import React from "react";
import { useAppSelector } from "../../state/hooks";
import './loaderModal.css';

const LoaderModal = () => {
  const isOpen = useAppSelector((state) => state.dialogState.bLoader);
  return (
    <div
      className={`${isOpen ? 'block' : 'hidden'
        } absolute z-20 inset-0 h-full w-full flex justify-center items-center bg-black bg-opacity-90`}>
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div className="loader"></div>
      </div>
    </div>
  );
};

export default LoaderModal;