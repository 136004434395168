import React from "react";
import moment from "moment";
import { GameType } from "../../../../type/gameType";
import { getBoringAvatar } from "../../../../util/boringAvatar";

interface Props {
  data: GameType;
}

const GameItem: React.FC<Props> = ({ data }) => {
  return (
    <a
      target="_blank"
      className="cursor-pointer "
    >
      <div className="flex justify-between px-2 py-6 group md:px-8 hover:bg-gray-light-1">
        <div className="flex items-center gap-2">
          <p className="text-xs text-gray-light-5">
            {moment(data.blockTime).format("DD MMM YY")}
          </p>
          <div className="flex items-center gap-1">
            <img
              src={data.image}
              className="w-5 h-5"
              alt="avatar"
            />
            <p className="text-xs md:text-sm text-gray-light-5 group-hover:text-white">
              {data.nftName}
            </p>
            <p className="text-sm font-normal leading-normal opacity-50 hover:text-[#FFF]">
              {data.game}
            </p>
          </div>
          <img
            src="/credpoints/external_link.svg"
            className="hidden group-hover:block w-[16px] h-[16px]"
            alt="cred"
          />
        </div>
        <div className="flex items-center gap-2">
          <p className="text-sm font-bold">{data.point ? data.point : 0}</p>
          <img
            src="/credpoints/cred.svg"
            className="w-[22px] h-[22px]"
            alt="cred"
          />
        </div>
      </div>
      <div className="h-px border border-gray-light-1 border-opacity-20" />
    </a>
  );
};

export default GameItem;
