import React from "react";
import { toggleMintNFT, toggleUpdateUsername, toggleWalletPanel } from "../../../state/dialog";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { useState } from "react";
import { getBoringAvatar } from "../../../util/boringAvatar";
import { reset, updateInitInviteCode } from "../../../state/credpoints";
import { reset as resetLeaderboard } from "../../../state/leaderboard";
import { useNavigate } from "react-router-dom";
import jwtEncode from 'jwt-encode';
import { setProfileName } from "../../../api/profile";
import { updateProfileName } from "../../../state/profile";
import { WalletType } from "../../../type/walletType";
import { useWallet } from "../../../hook/useWallet";
import { SECRETKEY } from "../../../constants";

const ConnectButton = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { connected, address, disconnect } = useWallet();

  const [isOpen, toggleOpen] = useState(false);
  const profileName = useAppSelector((state) => state.profileState.profileName)
  const userName = useAppSelector((state) => state.profileState.userName);

  const handleSetName = async (name: string) => {
    // console.log(profileName, name)
    if (address) {
      const token = jwtEncode({ wallet: address }, SECRETKEY);
      const res = await setProfileName(name, token)
      if (res.success) {
        // console.log(profileName, res.name)
        dispatch(updateProfileName(res.name))
      }
    }
  };

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleCopyToClipboard = () => {
    const textToCopy = address?.slice(0, 5) + '...' + address?.slice(-4);
    if (textToCopy && address) {
      navigator.clipboard.writeText(address)
        .then(() => {
          setTooltipVisible(true);
          setTimeout(() => setTooltipVisible(false), 2000);
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
        });
    }
  };

  return (
    <>
      {connected ? (
        <div className="relative flex flex-col mb-10">
          <div
            className="absolute z-0 bg-[#222222] -left-[30px] w-[252px] flex flex-col items-center gap-6 -top-[5px] px-6 py-[18px] rounded-[35px] hover:bg-[#ffffff0f] cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              // toggleOpen(!isOpen);
              // dispatch(toggleUpdateUsername(true));
            }}
          >
            <div className="flex items-center justify-center">
              <span className="text-xl font-semibold leading-[100%]">
                {userName ? "@"+userName : address == profileName ? profileName.slice(0, 7) + "..." + profileName.slice(-3) : profileName}
              </span>
            </div>
            {/* {isOpen && (
              <>
                <div className="grid">
                  {address &&
                    <div
                      className="w-[200px] group relative z-50 flex items-center mb-4"
                      onClick={() => handleSetName(address)}
                    >
                      {address == profileName ?
                        <img src="/credpoints/checkOn.svg" className="w-[24px] h-[24px] mr-2 text-white" alt="cred" />
                        :
                        <img src="/credpoints/checkOff.svg" className="w-[24px] h-[24px] mr-2 text-white" alt="cred" />
                      }
                      <div className="grid">
                        <p className="font-[Inter] group-hover:font-bold font-[600px]">
                          Use wallet address
                        </p>
                        <p className="font-[Inter] text-gray-light-3 group-hover:font-bold font-[500px]">
                          {address?.slice(0, 7)}...{address?.slice(-3)}
                        </p>
                      </div>
                    </div>
                  }
                  <div className="w-[200px] grid relative z-50">
                    {ansList.map((ansName, index) => (
                      <div
                        key={index}
                        className="flex items-center mb-4"
                        onClick={() => handleSetName(ansName)}
                      >
                        {ansName == profileName ?
                          <img src="/credpoints/checkOn.svg" className="w-[24px] h-[24px] mr-2 text-white" alt="cred" />
                          :
                          <img src="/credpoints/checkOff.svg" className="w-[24px] h-[24px] mr-2 text-white" alt="cred" />
                        }
                        <div className="grid group">
                          <p className="font-[Inter] group-hover:font-bold">
                            Use ANS name
                          </p>
                          <p className="font-[Inter] text-gray-light-3 group-hover:font-bold font-[500px]">
                            {ansName}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  {twitterId && <div
                    className="w-[200px] group flex relative z-50 items-center"
                    onClick={() => handleSetName(twitterId)}
                  >
                    {twitterId == profileName ?
                      <img src="/credpoints/checkOn.svg" className="w-[24px] h-[24px] mr-2 text-white" alt="cred" />
                      :
                      <img src="/credpoints/checkOff.svg" className="w-[24px] h-[24px] mr-2 text-white" alt="cred" />
                    }
                    <div className="grid">
                      <p className="font-[Inter] group-hover:font-bold">
                        Use X handle
                      </p>
                      <p className="font-[Inter] text-gray-light-3 group-hover:font-bold font-[500px]">
                        {twitterId}
                      </p>
                    </div>
                  </div>
                  }
                </div>
              </>
            )} */}
          </div>
          <div>
            <div className="absolute w-[400px] left-14 top-16">
              <p
                className="text-sm font-semibold leading-[140%] text-[#B9B9B9] hover:text-[#FFFFFF] cursor-pointer"
                onClick={handleCopyToClipboard}
              >
                {address?.slice(0, 5) + '...' + address?.slice(-4)}
              </p>
              {tooltipVisible && (
                <div className="absolute top-[30px] -left-10 bg-black text-white text-xs rounded py-1 px-2">
                  Address copied to clipboard!
                </div>
              )}
            </div>
          </div>
          <div
            className="w-[200px] mt-3 flex justify-center cursor-pointer"
            onClick={() => dispatch(toggleWalletPanel(true))}
          >
          </div>
        </div>
      ) : (
        <>
          <div
            className="w-[200px] mt-3 flex justify-center cursor-pointer"
            onClick={() => dispatch(toggleWalletPanel(true))}
          >
          </div>
        </>
      )}
    </>
  );
};

export default ConnectButton;
