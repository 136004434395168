import React from "react";
import PointLogo from "./pointLogo";
import "./index.css";
import PrimaryButton from "../../../components/primaryButton";
import ReferralList from "./referralList";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { toggleReferral, toggleSuggestVerifyModal } from "../../../state/dialog";
import SuggestVerify from "../../../components/suggestVerify";
import { WalletType } from "../../../type/walletType";

const Referral = () => {
  const dispatch = useAppDispatch();
  const referralList = useAppSelector(
    (state) => state.credpointsState.referralList
  );
  const visitorMode = useAppSelector(
    (state) => state.globalState.visitorMode
  );
  const walletType = useAppSelector((state) => state.globalState.walletType);

  return (
    <div className="w-full px-4">
      <div className="h-[332px] md:h-[450px] container mt-4 flex border border-gray-light-2 rounded-xl">
        <div className="min-w-full md:min-w-[50%] p-6 flex flex-col justify-center items-center gap-4">
          <PointLogo />
          <p className="pt-2 text-2xl font-bold text-center">from my invited frens</p>
          <div className="flex flex-col items-center w-full">
            <p className="text-sm text-center text-gray-light-3 w-[90%] md:w-[80%] pb-2">
              Get 50 Cred points for every fren that joins Cred using your invite code. <br /> Frens you have invited also get 50 Cred points.
            </p>
          </div>
          {visitorMode ?
            <PrimaryButton
              className="w-full text-sm md:hidden"
              onClick={() => dispatch(toggleSuggestVerifyModal(true))}
            >
              Verify account and join Cred
            </PrimaryButton> :
            <PrimaryButton
              className="w-full py-4 text-sm md:hidden"
              onClick={() => dispatch(toggleReferral(true))}
            >
              See referral list
            </PrimaryButton>
          }
        </div>
        <div className="hidden md:block w-px border border-gray-light-1 border-opacity-[20%]" />
        {visitorMode ?
          <SuggestVerify /> :
          <div className="hidden md:block min-w-[50%]">
            {walletType == WalletType.MOVEMENT ? (
              <div className="flex flex-col items-center justify-center w-full h-full">
                <img className="w-[133] justify-center mb-4" src="/credpoints/verify_suggestion_movement_general.svg" alt="effect1" />
                <p className="text-[22px] font-bold leading-normal mb-4">Eligible activities are</p>
                <button className="text-[22px] font-bold leading-normal text-[#F5E27D]">COMING SOON</button>
              </div>
            ) : (
              referralList.length > 0 ? (
                <ReferralList />
              ) : (
                <div className="flex items-center justify-center w-full h-full">
                  <span className="text-sm text-center">
                    Last 10 invited frens
                  </span>
                </div>
              )
            )}

          </div>
        }
      </div>
    </div>
  );
};

export default Referral;
