import React from "react";
import BridgeShowcase from "../../../../components/defi/bridgeShowcase";
import GlobalLink from "../../../../components/defi/globalLink";
import TwitterLink from "../../../../components/defi/twitterLink";
import "./index.css"
interface Props {
  data?: any;
}

const Swap: React.FC<Props> = ({ data }) => {
  return (
    <div id="swap" className="flex flex-col overflow-y-auto">
      <div className="flex flex-col h-fit border-b-[1px] border-[#626262] pb-4 mb-2">
        <div className="flex items-center justify-between h-8 mb-4 md:mt-4">
          <div className="flex">
            <img
              src="/credpoints/defi/potem.svg"
              className="w-6 h-6"
            />
            <p className="ml-2 font-semibold text-4">Liquidswap</p>
          </div>
          <div className="flex">
            <TwitterLink link="https://twitter.com/PontemNetwork" />
            <GlobalLink link="https://www.liquidswap.com" />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-wrap gap-y-2 justify-start w-[80%]">
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/aptos.svg", text: "APT" }}
              Item2={{ imgUrl: "/credpoints/token_icons/usdc.svg", text: "zUSDC" }}
            />
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/aptos.svg", text: "APT" }}
              Item2={{ imgUrl: "/credpoints/token_icons/usdc.svg", text: "wUSDC" }}
            />
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/aptos.svg", text: "APT" }}
              Item2={{ imgUrl: "/credpoints/token_icons/usdc.svg", text: "cUSDC" }}
            />
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/token_icons/usdc.svg", text: "zUSDC" }}
              Item2={{ imgUrl: "/credpoints/token_icons/usdt.svg", text: "zUSDT" }}
            />
          </div>
          <p className="text-[12px] text-gray-light-3 mt-2">Supported bridges for <b>USDC</b> and <b>USDT</b>: LayerZero, Wormhole, Celer</p>
        </div>
      </div>

      <div className="flex flex-col h-fit border-b-[1px] border-[#626262] pb-4 mb-2">
        <div className="flex items-center justify-between h-8 mb-4 md:mt-4">
          <div className="flex">
            <img
              src="/credpoints/defi/pancakeswap.svg"
              className="w-6 h-6"
            />
            <p className="ml-2 font-semibold text-4">Pancakeswap</p>
          </div>
          <div className="flex">
            <TwitterLink link="https://twitter.com/PancakeSwap" />
            <GlobalLink link="https://pancakeswap.finance" />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-wrap justify-start gap-y-2">
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/aptos.svg", text: "APT" }}
              Item2={{ imgUrl: "/credpoints/defi/pancakeswap.svg", text: "CAKE" }}
            />
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/aptos.svg", text: "APT" }}
              Item2={{ imgUrl: "/credpoints/token_icons/usdc.svg", text: "USDC" }}
            />
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/aptos.svg", text: "APT" }}
              Item2={{ imgUrl: "/credpoints/token_icons/gui_inu.svg", text: "GUI" }}
            />
          </div>
          <p className="text-[12px] text-gray-light-3 mt-2">Supported bridges for <b>USDC</b>: LayerZero</p>
        </div>
      </div>

      <div className="flex flex-col h-fit border-b-[1px] border-[#626262] pb-4">
        <div className="flex items-center justify-between h-8 mb-4 md:mt-4">
          <div className="flex">
            <img
              src="/credpoints/defi/cellana.svg"
              className="w-6 h-6"
            />
            <p className="ml-2 font-semibold text-4">Cellana Finance</p>
          </div>
          <div className="flex">
            <TwitterLink link="https://twitter.com/CellanaFinance" />
            <GlobalLink link="https://cellana.finance/" />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-wrap gap-y-2 justify-start w-[80%]">
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/defi/cellana.svg", text: "CELL" }}
              Item2={{ imgUrl: "/credpoints/aptos.svg", text: "APT" }}
            />
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/token_icons/amAPT.svg", text: "amAPT" }}
              Item2={{ imgUrl: "/credpoints/aptos.svg", text: "APT" }}
            />
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/token_icons/stAPT (Amnis).svg", text: "stAPT" }}
              Item2={{ imgUrl: "/credpoints/aptos.svg", text: "APT" }}
            />
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/token_icons/gui_inu.svg", text: "GUI" }}
              Item2={{ imgUrl: "/credpoints/aptos.svg", text: "APT" }}
            />
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/token_icons/usdc.svg", text: "USDC" }}
              Item2={{ imgUrl: "/credpoints/aptos.svg", text: "APT" }}
            />
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/token_icons/usdt.svg", text: "zUSDT" }}
              Item2={{ imgUrl: "/credpoints/token_icons/usdc.svg", text: "zUSDC" }}
            />
          </div>
          <p className="text-[12px] text-gray-light-3 mt-2">Supported bridges for <b>USDC</b>: LayerZero</p>
        </div>
      </div>

      <div className="flex flex-col h-fit border-b-[1px] border-[#626262] mt-4 pb-4">
        <div className="flex items-center justify-between h-8 mb-4 md:mt-4">
          <div className="flex">
            <img
              src="/credpoints/defi/kana_labs.svg"
              className="w-6 h-6"
            />
            <p className="ml-2 font-semibold text-4">Kana Labs</p>
          </div>
          <div className="flex">
            <TwitterLink link="https://twitter.com/kanalabs" />
            <GlobalLink link="https://app.kanalabs.io" />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex justify-start">
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/aptos.svg", text: "APT" }}
              Item2={{ imgUrl: "/credpoints/token_icons/usdc.svg", text: "USDC" }}
            />
          </div>
          <p className="text-[12px] text-gray-light-3 mt-2">Supported bridges for <b>USDC</b>: LayerZero</p>
        </div>
      </div>
    </div>
  );
};

export default Swap;
