import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import CredPoints from "./pages/credPoints";
import Leaderboard from "./pages/leaderboard";
import ProfilePage from "./pages/profilePage";
import About from "./pages/about";
import PrivacyPolicy from "./pages/privacyPolicy";
import { useAppDispatch, useAppSelector } from "./state/hooks";

import Sidebar from "./components/header/sidebar";
import WalletModal from "./components/modal/walletModal";
import { updateInitInviteCode } from "./state/credpoints";
import ActivityModal from "./pages/credPoints/defiActivity/activityModal";
import NftModal from "./pages/credPoints/nftBoard/nftModal";
import ReferralModal from "./pages/credPoints/referral/referralModal";
import TermsOfService from "./pages/tos";
import "./App.css";
import { updateWalletType } from "./state/global";

import ChangeAvatarModal from "./components/modal/changeAvatarModal";
import EmailVerifyModal from "./components/modal/emailVerifyModal";
import EligibleModal from "./pages/credPoints/defiActivity/eligibleModal";
import SuggestVerifyModal from "./components/modal/suggestVerifyModal";
import FirstVerifyModal from "./components/modal/firstVerifyModal";
import EligibleNftModal from "./pages/credPoints/nftBoard/eligibleNftModal";
import EligibleTokenModal from "./pages/credPoints/tokenBoard/eligibleTokenModal";
import EligibleGameModal from "./pages/credPoints/gameActivity/eligibleGameModal";
import TokenListModal from "./pages/credPoints/tokenBoard/tokenListModal";
import GameActivityModal from "./pages/credPoints/gameActivity/gameActivityModal";
import jwtEncode from "jwt-encode";
import ManageWalletModal from "./components/modal/manageWalletModal";
import DisconnectWalletModal from "./components/modal/disconnectWalletModal";
import ChangeUsernameModal from "./components/modal/changeUsernameModal";
import { WalletType } from "./type/walletType";
import UpdateUserNameModal from "./components/modal/updateUserNameModal";
import SuccessChangedUserNameModal from "./components/modal/successChangedUserNameModal";
import { fetchProfile, updateInitialized } from "./state/profile";
import { addWallet } from "./api/wallet";
import { useWallet } from "./hook/useWallet";
import { useAutoConnect } from "./wallet-adapter/AutoConnectProvider";
import { SECRETKEY } from "./constants";
import LoaderModal from "./components/modal/loaderModal";
import MintNFTModal from "./components/modal/mintNFTModal";
import { toggleChangeUsername } from "./state/dialog";
import DisconnectDiscordModal from "./components/modal/disconnectDiscordModal";
import DisconnectTwitterModal from "./components/modal/disconnectTwitterModal";

function App() {
  const dispatch = useAppDispatch();
  const { connected, address, network } = useWallet();

  const profile = useAppSelector(state => state.profileState)
  const initialized = useAppSelector(state => state.profileState.initialized);

  useEffect(() => {
    if (connected && address) {
      dispatch(fetchProfile({ wallet: address, network: network }));
    }
  }, [connected]);

  const { walletType } = useAutoConnect();
  useEffect(() => {
    if (walletType == "aptos") dispatch(updateWalletType(WalletType.APTOS));
    else if (walletType == "movement")
      dispatch(updateWalletType(WalletType.MOVEMENT));
  }, []);

  useEffect(() => {
    if (connected && profile.success && profile.userName == null) {
      dispatch(toggleChangeUsername(true));
    }
  }, [connected, profile.success])

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/credPoints" element={<CredPoints />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/tos" element={<TermsOfService />} />
      </Routes>
      <Sidebar />

      <WalletModal />
      <ChangeAvatarModal />

      <LoaderModal />

      <EmailVerifyModal />
      <ActivityModal />
      <EligibleModal />
      <EligibleNftModal />
      <EligibleTokenModal />
      <EligibleGameModal />
      <NftModal />
      <ReferralModal />

      <SuggestVerifyModal />
      <TokenListModal />
      <GameActivityModal />
      <FirstVerifyModal />
      <ManageWalletModal />
      <DisconnectWalletModal />
      <ChangeUsernameModal />
      <UpdateUserNameModal />
      <MintNFTModal />
      <SuccessChangedUserNameModal />
      <DisconnectDiscordModal />
      <DisconnectTwitterModal />
    </div>
  );
}

export default App;
