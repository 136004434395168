import { Aptos, AptosConfig, Network } from "@aptos-labs/ts-sdk";

export * from "./defi";
export * from "./inviteCode";


export const SECRETKEY =
  process.env.REACT_APP_JWT_SECRET_KEY ?? "default-secret-key";
export const MOVEMENT_TOWNESQUARE_NAME_ADDRESS = "0x5bd50f71ab4a29f25e840c0301b99a921c1f499273ee4911c20085973bc3f03f::townesquare_name"

const aptosConfig = new AptosConfig({ network: Network.CUSTOM });
export const movement = new Aptos(aptosConfig);
