import './toggleSwitch.css';

import React, { useState } from 'react';

import { useWallet as useAptosWallet } from '@aptos-labs/wallet-adapter-react';
import { useAptosWallet as useMovementWallet } from '@razorlabs/wallet-kit';

import { reset } from '../../state/credpoints';
import { toggleWalletPanel } from '../../state/dialog';
import { updateWalletType } from '../../state/global';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { reset as resetLeaderboard } from '../../state/leaderboard';
import { WalletType } from '../../type/walletType';
import { getNetworkName } from '../../util';
import { useAutoConnect } from '../../wallet-adapter/AutoConnectProvider';
import { useWallet } from '../../hook/useWallet';
import { resetProfile } from '../../state/profile';

interface option {
  label: string;
  icon: string;
  type: WalletType;
}
const ToggleSwitch: React.FC = () => {
  const currentWalletType = useAppSelector((state) => state.globalState.walletType);
  const dispatch = useAppDispatch();
  const { setWalletType } = useAutoConnect();
  const [selected, setSelected] = useState(
    currentWalletType == WalletType.MOVEMENT ? "MOV" : "APT"
  );
  const [isOpen, setIsOpen] = useState(false);
  const { connected: aptosConnected, account, disconnect: aptosDisconnect } = useAptosWallet();
  const { connected: movementConnected, account: movementAccount, address: movementAddress, disconnect: movementDisconnect } = useMovementWallet();
  const isAptos = currentWalletType == WalletType.APTOS;
  
  const onChangeWalletType = (type: WalletType) => {
    if(currentWalletType == WalletType.APTOS && type == WalletType.MOVEMENT && !movementConnected){
      dispatch(toggleWalletPanel(true));
    }
    else if(currentWalletType == WalletType.MOVEMENT && type == WalletType.APTOS && !aptosConnected){
      dispatch(toggleWalletPanel(true));
    }
    
    if(currentWalletType == WalletType.APTOS && aptosConnected) {
      aptosDisconnect();
    } else if(currentWalletType == WalletType.MOVEMENT && movementConnected) {
      movementDisconnect();
    }
    
    dispatch(updateWalletType(type));
    setWalletType(getNetworkName(type));

    dispatch(reset(true));
    dispatch(resetLeaderboard(true)); 
    dispatch(resetProfile(true));
  };

  const connectWallet = (option: option) => {
    setSelected(option.label);
    dispatch(updateWalletType(option.type));
    setIsOpen(false);
  };

  return (
    <div>
      <div className="hidden md:block">
        <div
          className={`flex items-center justify-between border-2 rounded-[52px] w-[212px] h-14 border-primary-default p-1`}
        >
          <div
            className={`${
              isAptos === true ? "bg-primary-default w-full" : "w-[78px]"
            } h-full flex items-center pl-3 rounded-[52px]`}
          >
            <button
              className={`flex flex-row items-center justify-center gap-1 ${isAptos == true ? 'ml-4' : ''}`}
              onClick={() => {
                onChangeWalletType(WalletType.APTOS);
              }}
            >
              <img src="/header/aptos.svg" alt="" />
              {isAptos === true && (
                <p className="text-black text-base font-bold leading-[125%]">
                  Aptos
                </p>
              )}
            </button>
          </div>
          <div
            className={`${
              isAptos === false ? "bg-primary-default w-full" : "w-[78px]"
            } w-1/2 h-full flex items-center pl-3 rounded-[52px]`}
          >
            <button
              className="flex flex-row items-center justify-center gap-1"
              onClick={() => {
                onChangeWalletType(WalletType.MOVEMENT);
              }}
            >
              <img src="/header/movement-logo.svg" alt="" />
              {isAptos === false && (
                <p className="text-black text-base font-bold leading-[125%]">
                  Movement
                </p>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <div className="dropdown z-100">
          <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
            <img
              src={options.find((option) => option.label === selected)?.icon}
              alt={selected}
            />
            <span className="text-base font-semibold text-black">
              {selected}
            </span>
            <span className="arrow">&#9662;</span>
          </div>
          {isOpen && (
            <div className="dropdown-menu z-100">
              {options.map((option) => (
                <div
                  key={option.label}
                  className="dropdown-item"
                  onClick={() => connectWallet(option)}
                >
                  <img src={option.icon} alt={option.label} />
                  <span>{option.label}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ToggleSwitch;


const options: option[] = [
  { label: "APT", icon: "/header/aptos.svg", type: WalletType.APTOS },
  {
    label: "MOV",
    icon: "/header/movement-logo.svg",
    type: WalletType.MOVEMENT,
  },
];
