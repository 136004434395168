import React from "react";

import { useEffect, useState } from "react";
import { toggleChangeUsername, toggleMintNFT } from "../../state/dialog";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import jwtEncode from 'jwt-encode';
import { claimUsername } from "../../api/profile";
import { useWallet } from "../../hook/useWallet";
import { SECRETKEY } from "../../constants";
import { updateUserName } from "../../state/profile";
import { isValidInput } from "../../util/isValidInput";
import { WalletType } from "../../type/walletType";


const ChangeUsernameModal = () => {
  const isOpen = useAppSelector((state) => state.dialogState.bChangeUsername);
  const dispatch = useAppDispatch();

  const currentUserName = useAppSelector((state) => state.profileState.userName);
  const walletType = useAppSelector((state) => state.globalState.walletType);
  const [userName, setUserName] = useState('');
  const [claimStatus, setClaimStatus] = useState('');
  const { address } = useWallet();

  const handleChangeUserName = (e: any) => {
    setUserName(e.target.value);
  }

  const handleClaimUserNameClick = async () => {
    if (userName.length < 4) {
      setClaimStatus("Username must be longer than 4 characters");
      return;
    } else if (userName.length > 15) {
      setClaimStatus("Username can be max. 15 characters long");
      return;
    } else if (userName === currentUserName) {
      setClaimStatus("Username is already taken");
      return;
    } else if (!isValidInput(userName)) {
      setClaimStatus("Use only letters and numbers or underscore");
      return;
    }
    const token = jwtEncode({ wallet: address }, SECRETKEY);
    const isInit = true;
    const response = await claimUsername(userName, token, isInit);
    if (response.success === true) {
      dispatch(toggleChangeUsername(false));
      dispatch(updateUserName(userName));
      if (walletType == WalletType.MOVEMENT) {
        dispatch(toggleMintNFT(true));
      }
    } else {
      setClaimStatus(response?.error);
    }
  }

  return (
    <div
      className={`${isOpen ? 'block' : 'hidden'
        } absolute z-20 inset-0 h-screen flex justify-center items-center bg-black bg-opacity-90`}
    >
      <div>
        <div className="w-[96%] ml-2 md:w-[560px] h-[190px] border-x border-t border-x-[#FFF] border-t-[#FFF] rounded-t-3xl bg-[url('../public/credpoints/cred_back.png')] bg-cover">
        </div>
        <div className="w-[96%] md:w-[560px] ml-2 border-x border-b border-x-[#FFF] border-b-[#FFF] rounded-b-3xl p-6 flex flex-col justify-center items-center bg-[#121212] backdrop-blur-[20px]">
          <div className="flex flex-col items-center justify-center gap-2 p-6">
            <p className="text-[#FFF] text-2xl font-Grotesk font-bold leading-normal text-center">
              Claim CRED Social graph username
            </p>
            <p className="block md:hidden text-[#FFF] font-Inter text-sm font-medium pb-2 leading-[160%] text-center">
              This is how you will be known across <br /> Web3 when using CRED Social graph
            </p>
            <p className="hidden md:block text-[#FFF] font-Inter text-sm font-medium pb-2 leading-[160%]">
              This is how you will be known in the TowneSquare ecosystem
            </p>
            <div className="flex flex-col items-center justify-center w-[85%] gap-8 mt-6">
              <div className="flex flex-col items-center justify-center w-full">
                <div className="flex items-center w-full bg-black border rounded-full">
                  <span className="ml-8 opacity-50">@</span>
                  <input type="text" value={userName} placeholder="Input username" onChange={handleChangeUserName} className={`w-[80%] font-Inter px-2 py-3 bg-black border-none outline-none ${claimStatus ? 'border-[#E12E2E]' : ''}`} />
                </div>
                {claimStatus && (
                  <div className="flex items-start justify-start w-full">
                    <p className={`text-[15px] text-[#FF4471] font-Inter font-medium leading-normal mt-2`}>{claimStatus}</p>
                  </div>
                )}
                <div className="flex w-full gap-1 pt-2">
                  <img src="/credpoints/icon-info.svg" className="w-[22px] h-[22px] mr-2" alt="cred" />
                  <p className="hidden md:block font-Inter text-[#B9B9B9] text-sm font-medium leading-[160%] w-[70%]">
                    It can only contain letters, numbers and underscore.
                  </p>
                </div>
              </div>
              <button onClick={handleClaimUserNameClick} className="bg-primary-default md:w-[200px] h-[51px] py-3 px-8 rounded-[200px] text-black font-bold text-[16px] text-center"
              >
                Claim username
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeUsernameModal;