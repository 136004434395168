import React from "react";
import { toggleMintNFT } from "../../../state/dialog";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { WalletType } from "../../../type/walletType";
import { useWallet } from "../../../hook/useWallet";

const ManageWallet = () => {
  const dispatch = useAppDispatch();
  const walletType = useAppSelector((state) => state.globalState.walletType);
  const { connected } = useWallet();

  return (
    <>
      {connected && walletType == WalletType.MOVEMENT &&
        <div className={`bg-[#9264F8]/10 w-[90%] h-[298px] md:h-[144px] py-8 px-4 md:px-8 grid md:flex items-center  rounded-xl mb-4 md:justify-between`}>
          <>
            <div className="flex md:items-center w-[90%]">
              <img src="/profile/verify.svg" alt="X" className="absolute" />
            </div>
            <div className="flex justify-center mt-8 md:mt-0">
              <div className="grid w-full">
                <button onClick={() => { dispatch(toggleMintNFT(true)) }} className="md:w-[240px] h-[51px] py-3 px-8 rounded-[200px] bg-[#9264F8] hover:bg-secondary-default text-white font-bold text-[16px] text-center"
                >
                  Mint CRED Profile NFT
                </button>
              </div>
            </div>
          </>
        </div>
      }
    </>
  );
};

export default ManageWallet;