import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RankingType } from "../type/rankingType";
import { LastUserList } from "../type/lastUserList";

interface leaderboardStates {
  isLive: boolean;
  myRank: number;
  myMorePoint: number;
  lowerPercentage: number;
  topRankings: RankingType[];
  lastUserList: LastUserList[];
  connection: boolean;
  leaderboardTapIndex: number;
  initInviteCode: string | undefined;
}

const initialState: leaderboardStates = {
  isLive: true,
  myRank: 0,
  myMorePoint: 0,
  lowerPercentage: 0,
  topRankings: [],
  lastUserList: [],
  connection: false,
  leaderboardTapIndex: 0,
  initInviteCode: undefined
};

export const fetchRankings = createAsyncThunk(
  "leaderboard/fetch",
  async ({ wallet, network }: { wallet: string, network: string }, thunkAPI) => {
    const url = `${process.env.REACT_APP_BACKEND}/activity/leaderboard/${wallet}/${network}`;
    try {
      const res = await fetch(url);
      const result = await res.json();
      return result;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const leaderboardSlice = createSlice({
  name: "leaderboard",
  initialState,
  reducers: {
    reset: (state, action: PayloadAction<boolean>) => {
      state.isLive = true;
      state.myRank = 0;
      state.myMorePoint = 0;
      state.lowerPercentage = 0;
      state.topRankings = [];
      state.lastUserList = [];
      state.initInviteCode = undefined;
    },
    updateLeaderboardLive: (state, action: PayloadAction<boolean>) => {
      state.isLive = action.payload;
    },
    updateConnection: (state, action: PayloadAction<boolean>) => {
      state.connection = action.payload;
    },
    updateLeaderboardTapIndex: (state, action: PayloadAction<number>) => {
      state.leaderboardTapIndex = action.payload;
    },
    updateInitInviteCode: (state, action: PayloadAction<string | undefined>) => {
      state.initInviteCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRankings.fulfilled, (state, action) => {
      console.log("leaderboard", action.payload)
      if (!action.payload.statusCode && state.connection) {
        state.isLive = true;
        state.myRank = action.payload.rank;
        state.topRankings = action.payload.topRankings;
        state.lastUserList = action.payload.lastUserList;
        state.myMorePoint = action.payload.morePoint;
        state.lowerPercentage = action.payload.lowerPercentage;
      }
    })
  },
});

export const { reset, updateLeaderboardLive, updateConnection, updateLeaderboardTapIndex, updateInitInviteCode } = leaderboardSlice.actions;
export default leaderboardSlice.reducer;
