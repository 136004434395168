import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { DefiActivityType } from '../type/defiActivityType';
import { NftType } from '../type/nftType';
import Moralis from 'moralis';
import { useDispatch } from 'react-redux';
import { ReferralType } from '../type/referralType';
import { GameType } from '../type/gameType';
import { TokenType } from '../type/tokenType';
import jwtEncode from 'jwt-encode';
import { SECRETKEY } from '../constants';

interface credpointsStates {
  isLive: boolean;
  aptTxsPercentage: number | undefined;
  totalPoint: number;
  defiPoint: number;
  nftPoint: number;
  gamePoint: number;
  tokenPoint: number;
  defiActivities: DefiActivityType[];
  nfts: NftType[];
  rewardNFTPointPerDay: number;
  popularDeFi: string | undefined;
  longestNft: NftType | undefined;
  referralPoint: number;
  referralList: ReferralType[];
  gameList: GameType[];
  holdingTokenList: TokenType[];
  connection: boolean;
  inviteCode: string | undefined;
  initInviteCode: string | undefined;
  eligibleDefiTapIndex: number;
  eligibleTokenTapIndex: number;
  walletAddress: string;
  appDexPoint: number;
  appNFTPoint: number;
  appSocialPoint: number;
}

const initialState: credpointsStates = {
  isLive: true,
  aptTxsPercentage: undefined,
  totalPoint: 0,
  defiPoint: 0,
  nftPoint: 0,
  gamePoint: 0,
  tokenPoint: 0,
  defiActivities: [],
  nfts: [],
  rewardNFTPointPerDay: 0,
  popularDeFi: undefined,
  longestNft: undefined,
  referralPoint: 0,
  referralList: [],
  gameList: [],
  holdingTokenList: [],
  connection: false,
  inviteCode: undefined,
  initInviteCode: undefined,
  eligibleDefiTapIndex: 0,
  eligibleTokenTapIndex: 0,
  walletAddress: "",
  appDexPoint: 0,
  appNFTPoint: 0,
  appSocialPoint: 0,
};

export const fetchCredpoints = createAsyncThunk(
  'credpoints/fetch',
  async ({ wallet, inviteCode, network }: any, thunkAPI) => {
    // wallet = "0xc56eb3799e275290e37351e7da61659d7957697baa9f292cfd7ff3faf01b1535";
    // inviteCode = "7CUAX"

    const token = jwtEncode({ wallet }, SECRETKEY);
    const url = `${process.env.REACT_APP_BACKEND}/activity/point/${inviteCode}`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, wallet, network }),
    };
    try {
      const res = await fetch(url, options);
      const result = await res.json();

      return result;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const credpointsSlice = createSlice({
  name: 'credPoints',
  initialState,
  reducers: {
    reset: (state, action: PayloadAction<boolean>) => {
      console.log("credpoints", state);
      state.isLive = true;
      state.aptTxsPercentage = undefined;
      state.totalPoint = 0;
      state.defiPoint = 0;
      state.nftPoint = 0;
      state.gamePoint = 0;
      state.tokenPoint = 0;
      state.defiActivities = [];
      state.nfts = [];
      state.rewardNFTPointPerDay = 0;
      state.popularDeFi = undefined;
      state.longestNft = undefined;
      state.referralPoint = 0;
      state.referralList = [];
      state.gameList = [];
      state.holdingTokenList = [];
      state.inviteCode = undefined;
      state.initInviteCode = undefined;
      state.appDexPoint = 0;
      state.appNFTPoint = 0;
      state.appSocialPoint = 0;
    },
    updateCredPointsLive: (state, action: PayloadAction<boolean>) => {
      state.isLive = action.payload;
    },
    updateConnection: (state, action: PayloadAction<boolean>) => {
      state.connection = action.payload;
    },
    updateInitInviteCode(state, action: PayloadAction<string | undefined>) {
      state.initInviteCode = action.payload;
    },
    updateEligibleDefiTapIndex(state, action: PayloadAction<number>) {
      state.eligibleDefiTapIndex = action.payload;
    },
    updateEligibleTokenTapIndex(state, action: PayloadAction<number>) {
      state.eligibleTokenTapIndex = action.payload;
    },
    updateWalletAddress(state, action: PayloadAction<string>) {
      state.walletAddress = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCredpoints.fulfilled, (state, action) => {
      console.log("credpoints", action.payload)
      if (!action.payload.statusCode && state.connection) {
        state.isLive = true;
        state.aptTxsPercentage = action.payload.aptTxsPercentage;
        if (action.payload.totalPoint) {
          state.totalPoint = action.payload.totalPoint;
        }
        state.defiPoint = action.payload.defiPoint;
        state.nftPoint = action.payload.nftPoint;
        state.gamePoint = action.payload.gamePoint;
        state.tokenPoint = action.payload.tokenPoint;
        state.appDexPoint = action.payload.appDexPoint;
        state.appNFTPoint = action.payload.appNFTPoint;
        state.appSocialPoint = action.payload.appSocialPoint;
        state.defiActivities = action.payload.defiActivityList;
        state.nfts = action.payload.nftActivityList;
        state.rewardNFTPointPerDay =
          action.payload.rewardNFTPointPerDay ?? state.nfts.length * 50;
        state.popularDeFi = action.payload.popularDeFi;
        state.longestNft = action.payload.longestHoldingNFT;
        state.referralPoint = action.payload.referralPoint;
        if (action.payload.success || action.payload.referralList) {
          state.referralList = action.payload.referralList;
        }
        state.gameList = action.payload.gameList;
        state.holdingTokenList = action.payload.holdingTokenList;
        state.inviteCode = action.payload.inviteCode;
      }
    });
  },
});

export const {
  reset,
  updateCredPointsLive,
  updateConnection,
  updateInitInviteCode,
  updateEligibleDefiTapIndex,
  updateWalletAddress,
  updateEligibleTokenTapIndex
} = credpointsSlice.actions;
export default credpointsSlice.reducer;
