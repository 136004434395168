import React from "react";

import { useEffect, useState } from "react";
import { toggleSuccessChangedUsername, toggleUpdateUsername } from "../../state/dialog";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import jwtEncode from 'jwt-encode';
import { claimUsername } from "../../api/profile";
import { useWallet } from "../../hook/useWallet";
import { SECRETKEY } from "../../constants";


const UpdateUserNameModal = () => {
    const isOpen = useAppSelector((state) => state.dialogState.bUpdateUsername);
    const dispatch = useAppDispatch();

    const currentUserName = useAppSelector((state) => state.profileState.userName);
    const [userName, setUserName] = useState(currentUserName);
    const [claimStatus, setClaimStatus] = useState('');
    const { address } = useWallet();

    const handleChangeUserName = (e: any) => {
        setUserName(e.target.value);
    }
    const handleClaimUserNameClick = async () => {
        const token = jwtEncode({ wallet: address }, SECRETKEY);
        const isInit = true;
        const response = await claimUsername(userName, token, isInit);
        if (response.success === true) {
            dispatch(toggleUpdateUsername(false));
            dispatch(toggleSuccessChangedUsername(true));
        } else {
            setClaimStatus(response?.error);
        }
    }

    return (
        <div
            className={`${isOpen ? 'block' : 'hidden'
                } absolute z-20 inset-0 h-[900px] flex justify-center items-center bg-black bg-opacity-90`}
        >
            <div className="flex flex-col">
                <div className={`container connect-button p-4 md:p-8 w-[99%] md:w-[600px] flex flex-col items-start border border-gray-light-2 rounded-2xl`}>
                    <div className="grid w-full gap-4 overflow-visible">
                        <div className="flex flex-row items-stretch justify-between pb-14">
                            <p className="text-[22px] font-bold md:text-xl">
                                Change username
                            </p>
                            <button onClick={() => { dispatch(toggleUpdateUsername(false)) }}>
                                <img src="/profile/close.svg" alt="" />
                            </button>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-4 text-center">
                            <p className="text-[#FFF] text-lg font-medium">You can update your CRED Social <br className="block md:hidden" /> graph <br className="hidden md:block" /> username once every 30 days</p>
                        </div>
                        <div className="flex flex-col items-center justify-center pt-2 text-center">
                            <div className="flex flex-col justify-center items-center w-[90%]">
                                <input type="text" value={userName} onChange={handleChangeUserName} className={`md:w-3/4 w-full px-8 py-3 bg-black border rounded-full ${claimStatus ? 'border-[#E12E2E]' : ''}`} />
                                {claimStatus && (
                                    <p className={`text-[15px] text-[#E12E2E] font-medium leading-normal mt-2`}>{claimStatus}</p>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-4 md:flex-row pt-14">
                            <button onClick={() => { dispatch(toggleUpdateUsername(false)) }} className="border border-white w-[90%] md:w-[200px] h-[51px] py-3 px-8 rounded-[200px] text-white font-bold text-[16px] text-center"
                            >
                                Cancel
                            </button>
                            <button onClick={handleClaimUserNameClick} className="bg-primary-default w-[90%] md:w-[200px] h-[51px] py-3 px-8 rounded-[200px] text-black font-bold text-[16px] text-center"
                            >
                                Claim username
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateUserNameModal;