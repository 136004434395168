import React from "react";

const ComingSoon = () => {
  return (
    <div className="md:block hidden w-full px-4 h-[400px] mt-6">
      <div className="container relative border border-white/90 backdrop-blur-[20px] rounded-2xl h-full"
        style={{
          background: "linear-gradient(92deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 111.54%)",
        }}>
        <div className="absolute w-full h-full">
          <img src="/credpoints/comming/center.svg" className="relative left-[35%] top-[110px]" alt="" />
          <img src="/credpoints/comming/rightTop.svg" className="relative left-[390px] bottom-[245px]" alt="" />
          <img src="/credpoints/comming/rightTopLine.svg" className="relative left-[375px] bottom-[539px]" alt="" />
          <img src="/credpoints/comming/leftBottomGroup.svg" className="relative right-[0px] bottom-[587px]" alt="" />
          <img src="/credpoints/comming/banner.svg" className="relative left-[370px] bottom-[920px]" alt="" />
          <div className="relative bottom-[915px] left-[245px] w-[430px] items-center justify-center">
            <p className="mb-4 text-xl font-bold leading-normal text-center ">Get extra CRED rewards when downloading and using TowneSquare app</p>
            <button className="relative left-[120px] border-2 border-primary-default rounded-full px-6 py-3 text-xl font-bold leading-normal">COMING SOON</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;