import React from "react";
import LoadingState from "../../../../components/loadingstate";
import { DefiIcon, DefiName } from "../../../../constants/defi";
import { useAppSelector } from "../../../../state/hooks";
import { WalletType } from "../../../../type/walletType";

const LiquidSwap = () => {
  const defiActivities = useAppSelector(
    (state) => state.credpointsState.referralList
  );
  const popularDefi = useAppSelector(
    (state) => state.credpointsState.popularDeFi
  );
  const isLive = useAppSelector((state) => state.credpointsState.isLive);
  const walletType = useAppSelector((state) => state.globalState.walletType);

  return (
    <>
      {!isLive ? (
        <div className="container w-[260px] md:w-[320px] min-h-[140px] px-10 md:px-20 py-4 flex flex-col gap-4 justify-center items-center border border-gray-light-2 rounded-xl">
          <div className="flex w-full gap-4 h-9">
            <LoadingState className="w-1/3" />
            <LoadingState className="w-full" />
          </div>
          <LoadingState className="w-full h-9" />
        </div>
      ) : (
        <div className="container w-[260px] md:w-[320px] min-h-[140px] px-4 pt-4 flex flex-col items-center border border-gray-light-2 rounded-xl">
          {defiActivities.length > 0 && popularDefi ? (
            <>
              <div className="flex items-center gap-2">
                <img src={DefiIcon[popularDefi.toLowerCase()]} alt="swap" />
                <p className="text-2xl font-bold">
                  {DefiName[popularDefi.toLowerCase()]}
                </p>
              </div>
              <p className="mt-4 text-sm text-center md:text-base">
                is the DeFi platform
                <br /> that you like the most
              </p>
            </>
          ) : (
            walletType == WalletType.APTOS ? (
              <>
                <div className="flex items-center gap-2">
                  <p className="text-2xl font-bold">Favourite DeFi</p>
                </div>
                <p className="mt-4 text-xs text-center md:text-sm">
                  Here we’ll show the DeFi you interacted with the most.
                </p>
              </>
            ) : (
              <>
                <div className="flex items-center gap-2">
                  <img src="/credpoints/comming/defi.svg" alt="" />
                  <p className="text-2xl font-bold">DeFi</p>
                </div>
                <p className="px-6 mt-4 text-xs text-center md:text-sm">
                  Here you’ll see what’s the DeFi platform that you like the most
                </p>
              </>
            )
          )}
        </div>
      )}
    </>
  );
};

export default LiquidSwap;
