export const disconnectDis = async (wallet: string, network: string, token: any) => {
  const url = `${process.env.REACT_APP_BACKEND}/activity/disconnectDiscord`;
  const resData = JSON.stringify({
    wallet,
    network,
    token
  });
  const res = await (
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: resData
    })
  ).json();
  return res;
}

export const disconnectX = async (wallet: string, network: string, token: any) => {
  const url = `${process.env.REACT_APP_BACKEND}/activity/disconnectX`;
  const resData = JSON.stringify({
    wallet,
    network,
    token
  });
  const res = await (
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: resData
    })
  ).json();
  return res;
} 