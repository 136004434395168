import React from "react";

import { toggleSuccessChangedUsername, toggleUpdateUsername } from "../../state/dialog";
import { useAppDispatch, useAppSelector } from "../../state/hooks";


const SuccessChangedUserNameModal = () => {
    const isOpen = useAppSelector((state) => state.dialogState.bStatusChangedUsername);
    const dispatch = useAppDispatch();

    const currentUserName = useAppSelector((state) => state.profileState.userName);

    return (
        <div
            className={`${isOpen ? 'block' : 'hidden'
                } absolute z-20 inset-0 h-[900px] flex justify-center items-center bg-black bg-opacity-90`}
        >
            <div className="flex flex-col w-[90%] md:w-[400px]">
                <div className={`container connect-button p-4 md:p-6 w-full md:w-[400px] flex flex-col items-start border border-gray-light-2 rounded-2xl`}>
                    <div className="grid w-full overflow-visible">
                        <div className="flex flex-row items-stretch justify-between pb-8">
                            <p className="text-[20px] font-bold md:text-xl">
                                Change username
                            </p>
                            <button onClick={() => { dispatch(toggleUpdateUsername(false)) }}>
                                <img src="/profile/close.svg" alt="" />
                            </button>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-4 pb-4 text-center">
                            <img src="/profile/success.svg" alt="" />
                        </div>
                        <div className="flex flex-col items-center justify-center gap-4 text-center">
                            <p>Great! Your new username is</p>
                            <p>{currentUserName}</p>
                        </div>
                        <div className="flex flex-row items-center justify-center pt-8">
                            <button onClick={() => { dispatch(toggleSuccessChangedUsername(false)) }} className="bg-primary-default w-[90%] md:w-[200px] h-[51px] py-3 px-8 rounded-[200px] text-black font-bold text-[16px] text-center"
                            >
                                Great!
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessChangedUserNameModal;