import React from "react";
import PrimaryButton from "../../../components/primaryButton";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import {
  toggleFirstVerifyModal,
  toggleWalletPanel,
} from "../../../state/dialog";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { checkInviteCode, getProfile } from "../../../api/invite";
import { updateStep } from "../../../state/global";
import PrivacyPolicy from "../../../components/privacyPolicy";
import Twitter from "../../../components/connectSocial/twitter";
import Discord from "../../../components/connectSocial/discord";
import { useWallet } from "../../../hook/useWallet";
import { fetchProfile } from "../../../state/profile";

const Box = (boxVisible: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { connected, address, network } = useWallet();

  const initialized = useAppSelector((state) => state.profileState.initialized);
  const profile = useAppSelector((state) => state.profileState);

  const [otp, setOtp] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);
  const step = useAppSelector((state) => state.globalState.step);

  const onInviteCode = async () => {
    if (!address) return;

    try {
      const inputCode = otp.toUpperCase();
      const res = await checkInviteCode(inputCode, address, network);
      if (res.success == false)
        setError("The invite code is not valid. Find one on socials!");
      else if (res.success == true) {
        setTimeout(() => {
          dispatch(fetchProfile({ wallet: address, network: network }));
        }, 1000);
      }
    } catch (e) {
      setError("The invite code is not valid. Find one on socials!");
    }
  };

  useEffect(() => {
    if (step == 1) dispatch(toggleFirstVerifyModal(true));
  }, [step]);

  useEffect(() => {
    const checkSignup = async () => {
      if (connected && address && initialized) {
        dispatch(toggleWalletPanel(false));

        if (profile.success) {
          navigate("/credPoints");
        } else if (!profile.success && !profile.newWallet) {
          dispatch(updateStep(2)); //
        } else {
          dispatch(updateStep(1));
        }
      } else {
        dispatch(updateStep(0));
      }
    };
    checkSignup();
  }, [connected, initialized, profile.success]);

  const onConnectWallet = () => {
    if (!connected) dispatch(toggleWalletPanel(true));
  };

  return (
    <div
      className={`${boxVisible.boxVisible ? "block" : "hidden"} ${step == 1 ? "hidden md:flex" : "flex"
        } connect-button mt-16 md:mt-[15vh] flex-col items-center`}
    >
      <div
        className={`container connect-button mt-2 p-4 md:p-10 w-[95%] ${step == 1 ? "md:w-[770px] mb-[100px]" : "md:w-[550px]"
          } flex flex-col items-center border border-gray-light-2 rounded-xl`}
      >
        {step == 2 && (
          <>
            <p className="mt-4 text-base font-bold text-center md:text-xl">
              Insert an invite code
            </p>
            <p className="text-base text-center md:text-xl">
              to check out your Cred points!
            </p>
            <div className="mt-6">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={5}
                renderSeparator={<span className="w-3"></span>}
                renderInput={(props) => <input {...props} />}
                inputStyle="otp-input"
              />
            </div>
            <div
              className={`${error ? "flex" : "hidden"
                } items-center justify-center h-4 mt-2`}
            >
              {error && (
                <p className="text-sm font-semibold text-[#DC3232]">{error}</p>
              )}
            </div>
            <PrimaryButton
              className="md:mt-6 w-[300px] z-[4] mb-6 py-4 px-8"
              onClick={() => {
                onInviteCode();
              }}
            >
              <span className="text-sm font-semibold leading-normal text-black md:text-base">
                Insert invite code
              </span>
            </PrimaryButton>
            <div className="hidden md:flex">
              <img
                src="/credpoints/icon-info.svg"
                className="w-[22px] h-[22px] mr-2"
                alt="cred"
              />
              <p className="text-[#FFF] text-base font-medium not-italic leading-[125%]">
                You and your inviter will only earn referral points if this
                address is an active account on Aptos or is verified with X /
                Discord.
              </p>
            </div>
          </>
        )}
        {step == 0 && (
          <>
            <p className="mt-4 text-base text-center md:text-xl">
              Connect wallet to check out your Cred points!
            </p>
            <PrimaryButton
              className="mt-2 md:mt-8 z-[4]"
              onClick={() => onConnectWallet()}
            >
              <span className="text-sm md:text-base">Connect Wallet</span>
            </PrimaryButton>
          </>
        )}
        {step == 1 && (
          <>
            <div className="md:grid">
              <p className="text-base font-normal text-center md:text-xl">
                Hey fren, it looks like you’re either new on Aptos or haven’t
                made any CRED eligible transactions!
                <br />
                <b>
                  Verify you wallet by connecting your X or Discord account to
                  proceed.
                </b>
              </p>
              <div className="grid mt-12 mb-8">
                <Twitter isProfileModal={false} />
                <Discord isProfileModal={false} />
              </div>
              <p className="text-base font-normal text-center md:text-xl">
                Want to first check what Cred is all about?
              </p>
              <p
                onClick={() => navigate("/credPoints")}
                className="text-center text-third-default text-base md:text-[18px] font-normal mt-2 cursor-pointer"
              >
                Explore CRED as visitor
              </p>
            </div>
          </>
        )}
      </div>
      <div className="mt-8">
        <div className="text-base text-center md:text-xl">Powering</div>
        <div className="flex items-center gap-5 mt-5">
          <img src="/home/aptos.svg" alt="aptos" className="h-4 md:h-6" />
          <img src="/home/movement.svg" alt="aptos" className="h-4 md:h-6" />
        </div>
      </div>
      <div className="mt-8">
        <PrivacyPolicy />
      </div>
    </div>
  );
};

export default Box;
