import React from "react";
import { useWallet } from "../../hook/useWallet";
import { toggleDisconnectDiscord } from "../../state/dialog";
import { useAppSelector } from "../../state/hooks";
import jwtEncode from "jwt-encode";
import { disconnectDis } from "../../api/socials";
import { useDispatch } from "react-redux";
import { SECRETKEY } from "../../constants";
import { fetchProfile } from "../../state/profile";


const DisconnectDiscordModal = () => {
  const dispatch = useDispatch();
  const { address, network } = useWallet();

  const isOpenDisconnectModal = useAppSelector((state) => state.dialogState.bDisconnectDiscord);
  const discordId = useAppSelector((state) => state.profileState.discordId);

  const onClickDisConnectDiscord = async () => {
    const wallet = address;
    if (!wallet) {
      console.error("Wallet address is undefined");
      return;
    }
    const token = jwtEncode({ wallet: address }, SECRETKEY);

    const res = await disconnectDis(wallet, network, token)
    if (res.success == true) {
      dispatch((toggleDisconnectDiscord(false)));
      dispatch(fetchProfile({ wallet: address, network: network }) as any);
    }
  }

  return (
    <>
      <div
        className={`${isOpenDisconnectModal ? 'block' : 'hidden'
          } absolute z-0 inset-0 h-screen flex justify-center items-center bg-black bg-opacity-90`}
      >
        <div className="flex flex-col w-[90%] md:w-[428px] z-[9999]">
          <div className={`container connect-button p-4 md:p-6 w-full md:w-[428px] flex flex-col items-start border border-gray-light-2 rounded-2xl`}>
            <div className="grid w-full overflow-visible">
              <div className="flex flex-row items-stretch justify-between pb-8">
                <p className="text-[20px] font-bold md:text-xl">
                  Disconnect your Discord account
                </p>
                <button onClick={() => { dispatch(toggleDisconnectDiscord(false)) }}>
                  <img src="/profile/close.svg" alt="" />
                </button>
              </div>
              <div className="flex flex-col items-center justify-center gap-6 text-center">
                <p>Are you sure you want to disconnect <br />this account?</p>
                <p>{discordId}</p>
              </div>
              <div className="flex flex-col items-center justify-center pt-8">
                <button onClick={() => { onClickDisConnectDiscord() }} className="bg-primary-default w-[90%] md:w-[200px] h-[51px] py-3 px-8 rounded-[200px] text-black font-bold text-[16px] text-center"
                >
                  Disconnect
                </button>
                <button onClick={() => { dispatch(toggleDisconnectDiscord(false)) }} className="w-[90%] md:w-[200px] h-[51px] py-3 px-8  text-white font-bold text-[16px] text-center"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisconnectDiscordModal;