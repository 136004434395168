import jwtEncode from 'jwt-encode';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { registerSocial } from '../../../api/profile';
import { useWallet } from '../../../hook/useWallet';
import { magic } from '../../../pages/lib/magic';
import { updateStep } from '../../../state/global';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { fetchProfile, updateTwitterId } from '../../../state/profile';
import ConnectedButton from '../../connectedButton';
import PrimaryButton from '../../primaryButton';
import Tooltip from '../../tooltip';
import { SECRETKEY } from '../../../constants';
import { toggleDisconnectTwitter, toggleLoaderModal } from '../../../state/dialog';
import { getNetworkName } from "../../../util";
import { disconnectX } from '../../../api/socials';

const Twitter = ({ isProfileModal }: { isProfileModal: boolean }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { address, network } = useWallet();
  const [pathName, setPathName] = useState("");

  const suggestVerifyModal = useAppSelector(
    (state) => state.dialogState.bSuggestVerifyModal
  );
  const firstVerifyModal = useAppSelector(
    (state) => state.dialogState.bFirstVerifyModal
  );
  const initialized = useAppSelector((state) => state.profileState.initialized);
  const twitterId = useAppSelector((state) => state.profileState.twitterId);
  const profileViewed = useAppSelector(
    (state) => state.profileState.profileViewed
  );

  const isOpenDisconnectModal = useAppSelector((state) => state.dialogState.bDisconnectTwitter);
  const walletType = useAppSelector((state) => state.globalState.walletType);

  useEffect(() => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    setPathName(pathSegments[0]);
    if (!initialized && address && location.search.indexOf("twitter") > 0) {
      login();
    }
  }, [address]);

  const login = async () => {
    console.log("twitter login")
    try {
      dispatch(toggleLoaderModal(true));
      const result = await magic.oauth.getRedirectResult();
      const provider = result.oauth.provider;
      if (provider == "twitter" && address) {
        const userName = result.oauth.userInfo.preferredUsername;
        const profileImage = result.oauth.userInfo.profile;
        if (userName && profileImage) {
          const token = jwtEncode(
            { userName: userName, profileImage: profileImage },
            SECRETKEY
          );
          const res = await registerSocial(address, network, token, "twitter");

          if (res.success) {
            dispatch(updateTwitterId(res.userInfo.twitterId));
            if (pathName == "profile") {
            } else {
              dispatch(updateStep(2));
            }
          } else if (!res.success) {
            dispatch(toggleLoaderModal(false));

            toast.info(res.error, {
              progressStyle: { backgroundColor: "white" },
              className: "custom-toast-info",
              theme: "colored",
            });
          }
        }
      }
      dispatch(toggleLoaderModal(false));
    } catch {
      console.log("Not prepared yet");
    }
  };

  const authenticateWithTwitter = async () => {
    try {
      await magic.oauth.loginWithRedirect({
        provider: "twitter",
        redirectURI: new URL(
          !isProfileModal ? "/?twitter" : "/profile?twitter",
          window.location.origin
        ).href,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div
        className={`${isProfileModal
          ? "bg-[#1B1B1B] md:h-full w-[90%] border py-4 px-4 md:px-8 mb-4"
          : "md:h-[91px] bg-opacity-0 w-[100%] mb-8"
          } ${(!twitterId && suggestVerifyModal) || firstVerifyModal
            ? "h-[205px]"
            : "h-[358px]"
          } grid md:flex items-center border-gray-light-2 rounded-xl md:justify-between`}
      >
        {twitterId ? (
          <>
            <div className="flex md:items-center w-[90%]">
              <div className="justify-center container-light border w-16 h-16 md:w-[80px] md:h-[80px] border-gray-light-2 rounded-full">
                <div className="top-0 left-0  w-16 h-16 md:w-[80px] md:h-[80px] group rounded-full flex justify-center items-center">
                  <img
                    src="/credpoints/twitter.svg"
                    alt="X"
                    className="absolute w-6"
                  />
                </div>
              </div>
              <div className="grid items-center ml-4 md:ml-8">
                <p className="text-[20px] font-bold md:whitespace-nowrap text-secondary-default">
                  Connect your X account
                </p>
                <div className="flex items-center">
                  <p className="text-[20px] font-normal">Reward: 50</p>
                  <img
                    className="inline-block w-7 mx-[5px]"
                    src="/credpoints/cred.svg"
                    alt="copy"
                  />
                  <Tooltip label={<>You received the reward</>}>
                    <img
                      src="/credpoints/success.svg"
                      className="hidden w-[24px] h-[24px] md:inline-block"
                      alt="cred"
                    />
                  </Tooltip>
                </div>
                <div className="grid items-center justify-center md:flex">
                  <div className="flex items-center text-[20px]">
                    {"Verified: " + twitterId}
                  </div>
                  <p className="text-[#ff0000] ml-4 cursor-pointer hover:text-error"
                    onClick={() => dispatch(toggleDisconnectTwitter(true))}
                  >Disconnect</p>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="flex justify-center mt-8 md:mt-0">
                <ConnectedButton>Connected</ConnectedButton>
              </div>
              <button className="md:mt-2 mt-4 bg-black bg-opacity-40 hover:bg-opacity-100 flex items-center justify-center text-white md:w-[200px] md:h-[44px] rounded-[200px] text-[16px] text-center">
                <a href="https://twitter.com/0xcred" target="_blank">
                  <img
                    className="inline-block w-[26px] mr-[5px]"
                    src="/profile/0xcred.svg"
                    alt="copy"
                  />
                  Follow us on X
                  <img
                    className="inline-block ml-[5px] w-4"
                    src="/credpoints/arrow-right.svg"
                    alt="copy"
                  />
                </a>
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex md:items-center w-[90%]">
              <div className="justify-center items-center bg-[#1B1B1B] border w-16 h-16 md:w-[80px] md:h-[80px] border-gray-light-2 rounded-full">
                <div className="container-light top-0 left-0 w-16 h-16 md:w-[78px] md:h-[78px] group rounded-full flex justify-center items-center">
                  {isProfileModal && !profileViewed && (
                    <img
                      src="/credpoints/icon-warning.svg"
                      className="absolute left-[40px] md:left-[50px] -top-[6px] w-[32px] h-[32px]"
                      alt="cred"
                    />
                  )}
                  <img
                    src="/credpoints/twitter.svg"
                    alt="X"
                    className="absolute w-6"
                  />
                </div>
              </div>
              <div className="grid items-center ml-4 md:ml-8">
                <p className="text-[18px] md:text-[20px] font-bold md:whitespace-nowrap text-secondary-default">
                  Connect your X account
                </p>
                <div className="flex items-center">
                  <p className="text-[18px] md:text-[20px] font-normal">
                    Reward: 50
                  </p>
                  <img
                    className="inline-block w-6 ml-[5px]"
                    src="/credpoints/cred.svg"
                    alt="copy"
                  />
                </div>
                {isProfileModal && (
                  <p className="hidden md:block text-[20px] font-normal md:whitespace-nowrap text-gray-light-3">
                    Active account verification
                  </p>
                )}
              </div>
            </div>
            <div className="flex justify-center mt-8 md:mt-0">
              <div className="grid w-full gap-1">
                <PrimaryButton
                  onClick={authenticateWithTwitter}
                  className="bg-primary-default md:w-[200px] h-[51px] px-8 rounded-[200px] text-black font-bold text-[16px] text-center"
                >
                  Connect X
                </PrimaryButton>
                <button className="mt-4 md:mt-2 bg-black bg-opacity-40 hover:bg-opacity-100 flex items-center justify-center text-white md:w-[200px] md:h-[44px] rounded-[200px] text-[16px] text-center">
                  <a href="https://twitter.com/0xcred" target="_blank">
                    <img
                      className="inline-block w-[22.5px] mr-[5px]"
                      src="/profile/0xcred.svg"
                      alt="copy"
                    />
                    Follow us on X
                    <img
                      className="inline-block ml-[5px] w-4"
                      src="/credpoints/arrow-right.svg"
                      alt="copy"
                    />
                  </a>
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Twitter;
