import React from "react";
import {
  FC,
  ReactNode,
  useEffect,
  useState,
  createContext,
  useContext,
} from "react";

const AUTO_CONNECT_LOCAL_STORAGE_KEY = "AptosWalletAutoConnect";

export interface AutoConnectContextState {
  walletType: string | null;
  setWalletType(type: string): void;
}

export const AutoConnectContext = createContext<AutoConnectContextState>(
  {} as AutoConnectContextState
);

export function useAutoConnect(): AutoConnectContextState {
  return useContext(AutoConnectContext);
}

export const AutoConnectProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [walletType, setWalletType] = useState<string | null>(() => {
    try {
      const type = localStorage.getItem(AUTO_CONNECT_LOCAL_STORAGE_KEY);
      return type;
    } catch (e: any) {
      if (typeof window !== "undefined") {
        console.error(e);
      }
      return null;
    }
  });

  useEffect(() => {
    try {
      if (walletType != null) {
        localStorage.setItem(AUTO_CONNECT_LOCAL_STORAGE_KEY, walletType);
      }
    } catch (error: any) {
      if (typeof window !== "undefined") {
        console.error(error);
      }
    }
  }, [walletType]);

  return (
    <AutoConnectContext.Provider value={{ walletType, setWalletType }}>
      {children}
    </AutoConnectContext.Provider>
  );
};
