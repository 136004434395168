import './index.css';

import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import Header from '../../components/header';
import SuggestVerifyNavbar from '../../components/header/suggestVerifyNavbar';
import InviteCode from '../../components/inviteCode';
import LeaderboardTapItem from '../../components/leaderboardTapItem';
import { useWallet } from '../../hook/useWallet';
import { fetchCredpoints, updateCredPointsLive } from '../../state/credpoints';
import { updateVisitorMode } from '../../state/global';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  fetchRankings, updateConnection, updateLeaderboardLive, updateLeaderboardTapIndex
} from '../../state/leaderboard';
import { WalletType } from '../../type/walletType';
import Banner from './banner';
import Cards from './cards';
import ConnectionList from './connectionList';
import MyRanking from './myRanking';
import RankingList from './rankingList';

const Leaderboard = () => {
  const { connected, address, network } = useWallet();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const profile = useAppSelector(state => state.profileState);
  const initialized = useAppSelector(state => state.profileState.initialized);
  const visitorMode = useAppSelector(state => state.globalState.visitorMode);
  const currentTap = useAppSelector(state => state.leaderboardState.leaderboardTapIndex);
  const walletType = useAppSelector((state) => state.globalState.walletType);
  const isAptos = walletType == WalletType.APTOS;

  const profileSuccessRef = useRef(profile.success);
  const visitorModeRef = useRef(visitorMode);

  useEffect(() => {
    profileSuccessRef.current = profile.success;
    visitorModeRef.current = visitorMode;
  }, [profile.success, address, visitorMode]);

  useEffect(() => {
    const fetchDataTimeout = setTimeout(() => {
      if (!profileSuccessRef.current && !visitorModeRef.current) {
        navigate('/')
      }
    }, 5000);
    return () => {
      clearTimeout(fetchDataTimeout);
    };
  }, [profileSuccessRef, visitorModeRef]);

  useEffect(() => {
    if (!connected) {
      navigate("/");
    }
  }, [connected, address])

  useEffect(() => {
    if (connected && address && initialized && !profile.success) {
      // dispatch(updateVisitorMode(true));
    }
  }, [address, initialized]);

  useEffect(() => {
    dispatch(updateConnection(connected));

    if (connected && address && initialized && profile.success) {
      dispatch(updateLeaderboardLive(false));
      dispatch(fetchRankings({ wallet: address, network }))
    }
  }, [connected, address, initialized]);


  return (
    <div className="relative">
      <Header />
      <Banner />
      <div className="parallax" id="leaderboard">
        <SuggestVerifyNavbar />
        <div className="parallax__group">
          <div className="parallax__layer cred__effect4 z-[100]">
            <img src="/credpoints/effect1.png" />
          </div>
          <div className="parallax__layer cred__effect2">
            {isAptos && <img src="/credpoints/aptos_dots.png" className="cred_solana_dots" alt="solana_dots" />}
            <img src={isAptos ? "/credpoints/aptos_effect.png" : "/credpoints/effect4.png"} alt="effect4" />
          </div>
          <div className="parallax__layer cred__effect3">
            <img src="/credpoints/effect2.png" alt="effect3" />
          </div>
          <div className="parallax__layer cred__effect5">
            <img src={isAptos ? "/credpoints/effect5.png" : "/credpoints/movement_effect.png"} alt="effect5" />
          </div>
        </div>
        <div className="relative z-10 flex justify-center w-full">
          <div className={`w-full md:w-[700px] flex flex-col items-center ${visitorMode ? 'mt-[70px]' : 'mt-[116px]'}  mb-10`}>
            <InviteCode />
            <MyRanking />
            <Cards />
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <div className="flex justify-between w-[100%] md:hidden border-b-[1px] border-gray-light-3 z-50">
            <LeaderboardTapItem onClick={() => dispatch(updateLeaderboardTapIndex(0))} className={` font-bold ${currentTap == 0 && 'border-b-[3px]'} `}>
              {"Leaderboard"}
            </LeaderboardTapItem>
            <LeaderboardTapItem onClick={() => dispatch(updateLeaderboardTapIndex(1))} className={` font-bold ${currentTap == 1 && 'border-b-[3px]'} `}>
              {"Connect with X"}
            </LeaderboardTapItem>
          </div>
          <div className="flex justify-center">
            <div className={`w-full md:w-[70%] flex`}>
              <RankingList />
              <ConnectionList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
