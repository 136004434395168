import React from "react";
import { BloctoWallet } from "@blocto/aptos-wallet-adapter-plugin";
import { FaceWallet } from "@haechi-labs/face-aptos-adapter-plugin";
import { FewchaWallet } from "fewcha-plugin-wallet-adapter";
import { FlipperWallet } from "@flipperplatform/wallet-adapter-plugin";
import { MartianWallet } from "@martianwallet/aptos-wallet-adapter";
import { NightlyWallet } from "@nightlylabs/aptos-wallet-adapter-plugin";
import { OpenBlockWallet } from "@openblockhq/aptos-wallet-adapter";
import { PetraWallet } from "petra-plugin-wallet-adapter";
import { PontemWallet } from "@pontem/wallet-adapter-plugin";
import { RiseWallet } from "@rise-wallet/wallet-adapter";
import { OKXWallet } from "@okwallet/aptos-wallet-adapter";
import { TokenPocketWallet } from "@tp-lab/aptos-wallet-adapter";
import { TrustWallet } from "@trustwallet/aptos-wallet-adapter";
// import { MSafeWalletAdapter } from "msafe-plugin-wallet-adapter";
import { WelldoneWallet } from "@welldone-studio/aptos-wallet-adapter";
import {
  AptosWalletAdapterProvider,
  NetworkName,
} from "@aptos-labs/wallet-adapter-react";
import { AutoConnectProvider, useAutoConnect } from "./AutoConnectProvider";
import { FC, ReactNode } from "react";
import face from "./lib/faceInitialization";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SolWalletAdapter from "../solana-wallet-adapter/solWalletAdapter";

import {
  AptosWalletProvider,
  SuiWalletProvider,
  RazorSuiWallet,
  SuiWallet,
  EthosWallet,
  IDefaultWallet,
} from "@razorlabs/wallet-kit";

const WalletContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const wallets = [
    new PetraWallet(),
    new PontemWallet(),
    new OKXWallet(),
    new RiseWallet(),
    new FewchaWallet(),
    new MartianWallet(),
  ];
  const { walletType } = useAutoConnect();
  const autoConnect = walletType == "aptos";

  return (
    <AptosWalletAdapterProvider
      plugins={wallets}
      autoConnect={autoConnect}
      onError={(error) => {
        toast.info("Wallet connection canceled", {
          progressStyle: { backgroundColor: "white" },
          className: "custom-toast-info",
          theme: "colored",
        });
      }}
    >
      {children}
    </AptosWalletAdapterProvider>
  );
};
const MovementContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { walletType } = useAutoConnect();
  const autoConnect = walletType == "movement";

  return (
    <AptosWalletProvider autoConnect={autoConnect}>
      {children}
    </AptosWalletProvider>
  );
};

export const AppContext: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <ToastContainer />
      <AutoConnectProvider>
        <WalletContextProvider>
          <SolWalletAdapter>
            <MovementContextProvider>{children}</MovementContextProvider>
          </SolWalletAdapter>
        </WalletContextProvider>
      </AutoConnectProvider>
    </>
  );
};
