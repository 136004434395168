import { WalletType } from "../type/walletType";

export * from "./boringAvatar";
export * from "./moralis";
export * from "./url";

export const getNetworkName = (type: WalletType): string => {
  switch (type) {
    case WalletType.APTOS:
      return "aptos";
    case WalletType.MOVEMENT:
      return "movement";
    case WalletType.SOLANA:
      return "solana";
    case WalletType.MONAD:
      return "monad";
    default:
      return "";
  }
}