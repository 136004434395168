import React, { FC, ReactNode } from "react";

const Tooltip: FC<{ label: ReactNode; children: ReactNode; className?: ReactNode }> = ({
  children,
  label,
  className
}) => {
  return (
    <div className={`relative cursor-pointer group `} style={{ zIndex: "99999" }}>
      <div
        className={`absolute items-center hidden -translate-x-[30%] bg-opacity-0 group-hover:flex`}
        style={{ bottom: "calc(100% + 4px)" }}
      >
        <div className={`${className ? className : 'w-fit'} h-fit bg-black border border-[#626262] rounded-[4px] pl-4 pr-8 py-4`} style={{ zIndex: "99999" }}>
          <span
            color="white"
            className={`font-normal text-white text-[13px] whitespace-nowrap ${className ? className : ''}`}
          >
            {label}
          </span>
        </div>
      </div>
      {children}
    </div>
  );
};

export default Tooltip;
