import { useAptosWallet } from "@razorlabs/wallet-kit";
import { getBoringAvatar } from "../util";

export const useMintNFT = () => {
  const { signAndSubmitTransaction, account } = useAptosWallet();

  const mintNFT = async (userName: string, avatar: string) => {

    const res = await signAndSubmitTransaction({
      payload: {
        function:
          "0x5bd50f71ab4a29f25e840c0301b99a921c1f499273ee4911c20085973bc3f03f::townesquare_name::claim_townesquare_name",
        typeArguments: [],
        functionArguments: [userName, avatar],
      },
    });
    return res;
  };

  return mintNFT;
};
