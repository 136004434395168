import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwtEncode from "jwt-encode";
import { SECRETKEY } from "../constants";

interface profileStates {
  initialized: boolean;
  success: boolean;
  newWallet: boolean;
  profileViewed: boolean;
  discordId: string | null;
  twitterId: string | null;
  email: string | undefined;
  profileName: string | null;
  twitterImage: string | undefined;
  ansName: any[];
  avatar: string;
  requestEmail: string;
  emailVerify: boolean;
  userName: string;
  walletList: { wallet: string; type: string }[];
  code: string | undefined;
}

const initialState: profileStates = {
  initialized: false,
  success: false,
  newWallet: true,
  profileViewed: false,
  discordId: null,
  twitterId: null,
  profileName: null,
  requestEmail: "",
  email: undefined,
  twitterImage: undefined,
  ansName: [],
  avatar: "",
  emailVerify: false,
  userName: "",
  walletList: [],
  code: undefined
};

export const fetchProfile = createAsyncThunk(
  "profilePage/fetch",
  async ({ wallet, network }: { wallet: string, network: string }, thunkAPI) => {
    const token = jwtEncode({ wallet }, SECRETKEY);
    const url = `${process.env.REACT_APP_BACKEND}/activity/getProfile`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: token, network: network, wallet: wallet }),
    };
    try {
      const res = await fetch(url, options);
      const result = await res.json();

      return result;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetProfile: (state, action: PayloadAction<boolean>) => {
      state.newWallet = true;
      state.profileViewed = false;
      state.discordId = null;
      state.twitterId = null;
      state.ansName = [];
      state.avatar = "";
      state.twitterImage = "";
      state.requestEmail = "";
      state.email = undefined;
      state.userName = "";
      state.walletList = [];
      state.code = undefined;
      state.initialized = false;
    },
    updateInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
    updateDiscordId: (state, action: PayloadAction<string>) => {
      state.discordId = action.payload;
    },
    updateTwitterId: (state, action: PayloadAction<string>) => {
      state.twitterId = action.payload;
    },
    updateProfileViewed: (state, action: PayloadAction<boolean>) => {
      state.profileViewed = action.payload;
    },
    updateProfileImage: (state, action: PayloadAction<string>) => {
      state.avatar = action.payload;
    },
    updateProfileName: (state, action: PayloadAction<string>) => {
      state.profileName = action.payload;
    },
    toggleUpdateRequestEmail: (state, action: PayloadAction<string>) => {
      state.requestEmail = action.payload;
    },
    updateTwitterImage: (state, action: PayloadAction<string>) => {
      state.twitterImage = action.payload;
    },
    updateEmail: (state, action: PayloadAction<string | undefined>) => {
      state.email = action.payload;
    },
    updateUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      console.log("profile", action.payload);
      if (!action.payload.statusCode) {
        state.newWallet = action.payload.newWallet;
        state.profileViewed = action.payload.profileViewed;
        state.discordId = action.payload.discordId;
        state.twitterId = action.payload.twitterId;
        state.profileName = action.payload.profileName;
        state.ansName = action.payload.ansName;
        state.avatar = action.payload.avatar;
        state.twitterImage = action.payload.twitterImage;
        state.email = action.payload.email;
        state.emailVerify = action.payload.emailVerify;
        state.userName = action.payload.username;
        state.walletList = action.payload.walletList;
        state.code = action.payload.code;
        state.success = action.payload.success;
        state.initialized = true;
      }
    });
  },
});

export const {
  resetProfile,
  updateInitialized,
  updateDiscordId,
  updateTwitterId,
  updateProfileViewed,
  toggleUpdateRequestEmail,
  updateProfileImage,
  updateProfileName,
  updateEmail,
  updateUserName
} = profileSlice.actions;
export default profileSlice.reducer;
