import React from "react";
import { useAppSelector } from "../../../state/hooks";
import { RankingType } from "../../../type/rankingType";
import { WalletType } from "../../../type/walletType";
import RankingItem from "./rankingItem";

const RankingList = () => {
  const topRankings = useAppSelector(
    (state) => state.leaderboardState.topRankings
  );
  const currentTap = useAppSelector(
    (state) => state.leaderboardState.leaderboardTapIndex
  );
  const walletType = useAppSelector(
    (state) => state.globalState.walletType
  );
  const sortedTopRankings = [...topRankings].sort((a,b) => b.totalPoint - a.totalPoint);
  return (
    <div className={`${currentTap == 0 ? 'flex' : 'hidden md:flex'} mt-8 md:mt-20 md:py-5 md:w-[65%] w-full flex-col items-center`}>
      < p className="text-center text-[20px] md:text-[32px] font-bold leading-normal" >
        {walletType == WalletType.APTOS ? 'Top 100 Aptos CRED users' : 'Top 100 Movement CRED users'}
      </p >
      <div className="w-8 h-1 mt-2 border bg-secondary-default border-secondary-default" />
      <div
        className="container mt-8 w-[95%] border border-gray-light-1 md:border-gray-light-4 rounded-2xl"
        style={{ backdropFilter: 'blur(20px)' }}
      >
        <div className="">
          {sortedTopRankings.map((user, index) => (
            <RankingItem data={user} index={index} key={index} />
          ))}
        </div>
      </div>
    </div >
  );
};

export default RankingList;
