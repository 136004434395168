import React from "react";
import NftCollection from "../../../../components/defi/nftCollection";

interface Props {
  data?: any;
}

const tipData = [
  {
    title: "Rewards",
    items: [
      { descr: "1 - 10 amAPT/stAPT", price: "50" },
      { descr: ">10 - 30 amAPT/stAPT", price: "100" },
      { descr: ">30 - 80 amAPT/stAPT", price: "200" },
      { descr: ">80 amAPT/stAPT", price: "300" },
    ]
  }
];

const Tokens: React.FC<Props> = () => {
  return (
    <div className="flex flex-col px-4 overflow-y-scroll md:px-1 font-Inter md:overflow-hidden">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <NftCollection
          imgUrl={"/credpoints/staking_icons/animal.svg"}
          text={"Amnis Finance"}
          tipData={tipData}
          twitterLink={"https://twitter.com/AmnisFinance"}
          globalLink={"https://amnis.finance/"}
        />
      </div>
    </div>
  );
};

export default Tokens;
