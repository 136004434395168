import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import SuggestVerifyNavbar from "../../components/header/suggestVerifyNavbar";
import PrimaryButton from "../../components/primaryButton";
import PrivacyPolicy from "../../components/privacyPolicy";
import { useAppSelector } from "../../state/hooks";
import { useNavigate } from "react-router";
import { useWallet } from "../../hook/useWallet";


const About = () => {
  const visitorMode = useAppSelector(state => state.globalState.visitorMode)
  const navigate = useNavigate();
  const { connected, address } = useWallet();
  useEffect(() => {
    if (!connected) {
      navigate("/");
    }
  }, [connected, address])

  return (
    <div className="parallax" id="about">
      <Header />
      {visitorMode && <SuggestVerifyNavbar />}
      <div className="flex justify-center w-full">
        <div className={`w-full md:w-[730px] px-4 md:px-0 flex flex-col items-center ${visitorMode ? 'mt-16' : 'mt-44'} mb-10`}>
          <h1 className="text-2xl font-bold md:text-3xl">ABOUT CRED</h1>
          <p className="mt-8 text-base md:text-xl">
            Cred is a loyalty points system that rewards users for being active
            on-chain. We use custom made algorithms to calculate CRED points
            which you can get by using DeFi platforms and holding certain NFTs.
            We’re currently supporting Aptos ecosystem and expanding on other
            chains soon. <br />
            <br />
            What to do with CRED points? We’ll reveal more information soon.
            Stay tuned and follow all the news on our&nbsp;
            <Link to={'https://twitter.com/0xcred'}>
              <span className="font-bold underline cursor-pointer text-third-default">
                X account!
              </span>
            </Link>
          </p>
          <div
            className="flex flex-col items-center justify-center w-full py-12 mt-20 border border-gray-light-1 rounded-xl"
            style={{
              background:
                "linear-gradient(94.74deg, rgba(255, 255, 255, 0.14) 16.43%, rgba(255, 255, 255, 0) 108.74%)",
            }}
          >
            <p className="text-base text-center md:text-xl">
              Want to know more?
              <br />
              Drop us a question or feedback on X!
            </p>
            <a href="https://x.com/0xcred" target="_blank">
              <PrimaryButton className="mt-8">
                <div className="flex gap-2">
                  <p className="">Ask us anything on</p>
                  <img src="/about/x.svg" alt="x" />
                </div>
              </PrimaryButton>
            </a>
          </div>
          <div className="mt-[138px]">
            <PrivacyPolicy />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
