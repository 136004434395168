import React from "react";
import { ReferralType } from "../../../../type/referralType";
import moment from "moment";
import { getBoringAvatar } from "../../../../util/boringAvatar";

interface Props {
  data: ReferralType;
}

const ReferralItem: React.FC<Props> = ({ data }) => {
  return (
    <a
      target="_blank"
      href={`https://explorer.aptoslabs.com/account/${data.fromWallet}/transactions?network=mainnet`}
      className="cursor-pointer "
    >
      <div className="flex justify-between px-2 py-6 group md:px-8 hover:bg-gray-light-1">
        <div className="flex items-center gap-2">
          <p className="text-xs text-gray-light-5">
            {moment(data.createdAt).format("DD MMM YY")}
          </p>
          <div className="flex items-center gap-1">
            <img
              src={getBoringAvatar(data.toWallet)} crossOrigin="anonymous"
              className="w-5 h-5"
              alt="avatar"
            />
            <p className="text-xs md:text-sm text-gray-light-5 group-hover:text-white">
              {data.toWallet.slice(0, 5)}...{data.toWallet.slice(-4)}
            </p>
          </div>
          <img
            src="/credpoints/external_link.svg"
            className="hidden group-hover:block w-[16px] h-[16px]"
            alt="cred"
          />
        </div>
        <div className="flex items-center gap-2">
          <p className="text-sm font-bold">{data?.point || 50}</p>
          <img
            src="/credpoints/cred.svg"
            className="w-[22px] h-[22px]"
            alt="cred"
          />
        </div>
      </div>
      <div className="h-px border border-gray-light-1 border-opacity-20" />
    </a>
  );
};

export default ReferralItem;
