import React from "react";
import { useAppSelector } from "../../../../state/hooks";
import NftItem from "./nftItem";

const NftList = () => {
  const rewardPerDay = useAppSelector(state => state.credpointsState.rewardNFTPointPerDay);
  const nfts = useAppSelector(state => state.credpointsState.nfts);
  return (
    <div className="flex flex-col items-center w-full py-5">
      <p className="text-center">Eligible NFTs in your wallet</p>
      <div className="w-8 h-px mt-2 border border-primary-default" />
      <div className="flex items-center mt-6">
        <p className="text-center">You get {rewardPerDay.toLocaleString()}&nbsp;</p>
        <img src="/credpoints/cred.svg" alt="cred" className="w-4 h-4" />
        <p className="text-center">&nbsp;/day</p>
      </div>
      <div className="w-full px-2 mt-4 md:px-8">
        <div className="nft-board pr-4 w-full h-screen md:h-[300px] flex flex-wrap gap-x-4 gap-y-4 overflow-y-scroll">
          {nfts.map((history, index) => (
            <NftItem data={history} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default NftList;
