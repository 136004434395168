import React from "react";
import { useAppSelector } from "../../../../state/hooks";
import TokenItem from "./tokenItem";

const TokenList = () => {
  const referrals = useAppSelector(state => state.credpointsState.holdingTokenList);

  return (
    <div className="flex flex-col items-center w-full py-5">
      <p className="hidden text-center md:block">Last 10 rewards from holding tokens</p>
      <p className="block text-center md:hidden">Last 10 rewards from tokens</p>
      <div className="w-8 h-px mt-2 border border-primary-default" />
      <div className="w-full md:pr-8">
        <div className="history-board w-full h-screen md:h-[380px] flex flex-col overflow-y-auto md:px-0 px-4">
          {referrals.slice(0, 9).map((referral, index) => (
            <TokenItem data={referral} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TokenList;
