import React from "react";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../state/hooks";
import { getImageUrl, getMetadata } from "../../../../api/metadata";
import { getImageURL } from "../../../../util/url";
import LoadingState from "../../../../components/loadingstate";
import LoadingImage from "../../../../components/loadingImage";
import { WalletType } from "../../../../type/walletType";

const LongestNft = () => {
  const isLive = useAppSelector(state => state.credpointsState.isLive);
  const longest = useAppSelector(state => state.credpointsState.longestNft);

  const [imageLink, setImageLink] = useState<string | undefined>(undefined);
  const walletType = useAppSelector((state) => state.globalState.walletType);

  useEffect(() => {
    const getImage = async () => {
      if (!longest) return;
      try {
        const res = await getMetadata(longest);
        const initUrl = res.url;
        if (initUrl.endsWith(".json") == true) {
          const res = await getImageUrl(initUrl);
          setImageLink(getImageURL(res.image));
        } else {
          setImageLink(getImageURL(res.url));
        }
      } catch (e) { }
    };
    getImage();
  }, [longest]);

  return (
    <>
      {!isLive ? (
        <div className="container w-[260px] md:w-[320px] min-h-[140px] px-10 md:px-20 py-4 flex flex-col gap-4 justify-center items-center border border-gray-light-2 rounded-xl">
          <div className="flex w-full gap-4 h-9">
            <LoadingState className="w-1/3" />
            <LoadingState className="w-full" />
          </div>
          <LoadingState className="w-full h-9" />
        </div>
      ) : (
        <div className="container w-[260px] md:w-[320px] min-h-[140px] px-4 pt-4 flex flex-col items-center border border-gray-light-2 rounded-xl">
          {longest ? (
            <>
              <div className="flex items-center gap-2">
                <LoadingImage url={imageLink} className="w-12 h-12" />
                <p className="text-xl font-bold md:text-2xl">{longest?.nftName}</p>
              </div>
              <p className="mt-4 text-sm text-center md:text-base">
                is your longest-holding NFT
              </p>
            </>
          ) : (
            walletType == WalletType.APTOS ? (
              <>
                <div className="flex items-center gap-2">
                  <p className="text-xl font-bold md:text-2xl whitespace-nowrap">
                    Longest holding NFT
                  </p>
                </div>
                <p className="mt-4 text-xs text-center md:text-sm">
                  Here we’ll show what’s the NFT you’re holding for the longest period
                  of time
                </p>
              </>
            ) : (
              <>
                <div className="flex items-center gap-2">
                  <img src="/credpoints/comming/nft.svg" alt="" />
                  <p className="text-xl font-bold md:text-2xl whitespace-nowrap">
                    NFT
                  </p>
                </div>
                <p className="px-4 mt-4 text-xs text-center md:text-sm">
                  Here you’ll see which NFT is your longest-hodling NFT
                </p>
              </>
            )
          )}
        </div>
      )}
    </>
  );
};

export default LongestNft;
