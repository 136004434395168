import React from "react";
import PointLogo from "./pointLogo";
import "./index.css";
import PrimaryButton from "../../../components/primaryButton";
import ActivityList from "./activityList";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { toggleActivityList, toggleEligibleModal, toggleSuggestVerifyModal } from "../../../state/dialog";
import SuggestVerify from "../../../components/suggestVerify";
import { WalletType } from "../../../type/walletType";

const DefiActivity = () => {
  const dispatch = useAppDispatch();
  const defiActivities = useAppSelector(state => state.credpointsState.defiActivities);
  const visitorMode = useAppSelector((state) => state.globalState.visitorMode);
  const walletType = useAppSelector((state) => state.globalState.walletType);

  return (
    <div className="w-full px-4">
      <div className="h-[332px] md:h-[450px] container mt-4 flex border border-gray-light-1 rounded-xl">
        <div className="min-w-full md:min-w-[50%] px-6 py-8 flex flex-col justify-center items-center gap-4">
          <PointLogo />
          <p className="pt-2 text-2xl font-bold">from DeFi trading</p>
          <p className="text-sm text-center text-gray-light-3">
            {walletType == WalletType.APTOS ? "Get points by being active on Aptos DeFi." : "Get points by being active on Movement DeFi."}
          </p>
          {walletType == WalletType.APTOS && <p onClick={() => dispatch(toggleEligibleModal(true))} className="pb-2 text-sm font-normal cursor-pointer text-third-default">Show eligible activities and pairs</p>}
          {visitorMode ?
            <PrimaryButton
              className="w-full text-sm md:hidden"
              onClick={() => dispatch(toggleSuggestVerifyModal(true))}
            >
              Verify account and join Cred
            </PrimaryButton> :
            <PrimaryButton
              className="w-full py-4 text-sm md:hidden"
              onClick={() => dispatch(toggleActivityList(true))}
            >
              See last 10 DeFi activities
            </PrimaryButton>
          }
        </div>
        <div className="hidden md:block w-px border border-gray-light-1 border-opacity-[20%]" />
        {visitorMode ?
          <SuggestVerify /> :
          <div className="hidden md:block min-w-[50%]">
            {walletType == WalletType.MOVEMENT ? (
              <div className="flex flex-col items-center justify-center w-full h-full">
                <img className="w-[133] justify-center mb-4" src="/credpoints/verify_suggestion_movement_general.svg" alt="effect1" />
                <p className="text-[22px] font-bold leading-normal mb-4">Eligible activities are</p>
                <button className="text-[22px] font-bold leading-normal text-[#F5E27D]">COMING SOON</button>
              </div>
            ) : (
              defiActivities.length > 0 ? (

                <ActivityList />

              ) : (

                <div className="flex items-center justify-center w-full h-full">

                  <span className="text-sm text-center">

                    Here we’ll show the last 10 activities,

                    <br /> when you interact with DeFi protocols

                  </span>
                </div>
              )
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default DefiActivity;
