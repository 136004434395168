import React from "react";

import { toggleDisconnectWallet, toggleManageWallet, toggleWalletPanel } from "../../state/dialog";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import PrimaryButton from "../primaryButton";

const DisconnectWalletModal = () => {
  const isOpen = useAppSelector((state) => state.dialogState.bDisconnectWallet);
  const dispatch = useAppDispatch();

  return (
    <>
      <div
        className={`${isOpen ? 'block' : 'hidden'
          } absolute z-20 inset-0 h-[900px] flex justify-center items-center bg-black bg-opacity-90`}
      >
        <div className="flex flex-col">
          <div className={`container connect-button p-4 md:p-10 w-full md:w-[770px] flex flex-col items-start border border-gray-light-2 rounded-2xl`}>
            <div className="grid w-full gap-4 overflow-visible">
              <div className="flex flex-row items-stretch justify-between pb-14">
                <p className="text-[22px] font-bold md:text-xl">
                  Disconnect wallet
                </p>
                <button onClick={() => { dispatch(toggleDisconnectWallet(false)) }}>
                  <img src="/profile/close.svg" alt="" />
                </button>
              </div>
              <div className="flex flex-col items-center justify-center gap-4">
                <p className="text-[#FFF] text-lg font-medium px-22 text-center">Are you sure you want to disconnect Aptos wallet</p>
                <div className="flex flex-row items-center justify-center gap-4">
                  <div className="w-10 h-10 p-2 border rounded-full border-gray-light-2">
                    <img src="/credpoints/aptos.svg" alt="" />
                  </div>
                  <p className="text-[17px] font-bold">0x6f762a...cb3fde ?</p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center pt-4 text-center">
                <p className="text-[15px] font-normal pb-6">When you disconnect this wallet, all CRED <br className="block md:hidden" /> points associated with it will be <br className="hidden md:block" /> removed <br className="block md:hidden" /> from you CRED Social graph profile. </p>
                <p className="text-[15px] font-normal">You can always reconnect the wallet later and retrieve the CRED points.</p>
              </div>
              <div className="flex flex-col items-center justify-center gap-4 pt-4 md:flex-row">
                <button onClick={() => { dispatch(toggleDisconnectWallet(false)) }} className="border-[#FFFFFF] border w-[90%] md:w-[200px] h-[51px] py-3 px-8 rounded-[200px] text-white font-bold text-[16px] text-center"
                >
                  Back
                </button>
                <PrimaryButton onClick={() => { dispatch(toggleDisconnectWallet(false)); dispatch(toggleManageWallet(false)); dispatch(toggleWalletPanel(false)) }} className="bg-primary-default w-[90%] md:w-[200px] h-[51px] py-3 px-8 rounded-[200px] text-black font-bold text-[16px] text-center"
                >
                  Disconnect
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisconnectWalletModal;