import React from "react";

import { useState } from "react";
import {
  toggleDisconnectWallet,
  toggleManageWallet,
  toggleWalletPanel,
} from "../../state/dialog";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import PrimaryButton from "../primaryButton";
import Tooltip from "../tooltip";

interface WalletType {
  icon: string;
  address: string;
  isMainWallet: boolean;
}

const ManageWalletModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.dialogState.bManageWallet);

  const [aptosList, setAptosList] = useState<WalletType[]>(AptosWalletList);
  const [solanaList, setSolanaList] = useState<WalletType[]>(SolanaWalletList);

  const handleAptosMainWallet = (address: string) => {
    setAptosList((prevState) =>
      prevState.map((wallet) =>
        wallet.address === address
          ? { ...wallet, isMainWallet: !wallet.isMainWallet }
          : wallet
      )
    );
  };

  const handleSolanaMainWallet = (address: string) => {
    setSolanaList((prevState) =>
      prevState.map((wallet) =>
        wallet.address === address
          ? { ...wallet, isMainWallet: !wallet.isMainWallet }
          : wallet
      )
    );
  };

  return (
    <>
      <div
        className={`${
          isOpen ? "block" : "hidden"
        } absolute z-20 inset-0 h-[900px] flex justify-center items-center bg-black bg-opacity-90`}
      >
        <div className="flex flex-col">
          <div
            className={`container connect-button p-4 md:p-10 w-full md:w-[770px] flex flex-col items-start border border-gray-light-2 rounded-tr-2xl rounded-tl-2xl`}
          >
            <div className="grid gap-4 overflow-visible">
              <div className="flex flex-row justify-between">
                <p className="text-[22px] font-bold md:text-xl">
                  Manage wallets
                </p>
                <button
                  onClick={() => {
                    dispatch(toggleManageWallet(false));
                  }}
                >
                  <img src="/profile/close.svg" alt="" />
                </button>
              </div>
              <p className="text-[15px] font-normal">
                Connect all your <b>Solana</b> and <b>Aptos</b> wallets. Earn
                CRED rewards from every connected wallet!
              </p>
              <div className="flex flex-col items-center justify-between gap-4 mt-12 overflow-y-scroll h-[340px] history-board pr-2">
                {aptosList.map((wallet, index) => (
                  <div
                    tabIndex={index}
                    className="flex flex-row justify-between w-full"
                    key={index}
                  >
                    <div className="flex flex-row items-center justify-center gap-2 md:gap-4">
                      <div className="p-2 border rounded-full h-9 w-9 border-gray-light-2">
                        <img src={wallet.icon} alt="" />
                      </div>
                      <p className="text-[15px] font-bold">
                        {wallet.address.slice(0, 6) +
                          "..." +
                          wallet.address.slice(-6)}
                      </p>
                      <Tooltip
                        label={
                          wallet.isMainWallet ? (
                            <>
                              This is your Main Aptos Wallet.
                              <br />
                              Aptos tokens sent to you in the TowneSquare
                              ecosystem will be received here
                            </>
                          ) : (
                            <>
                              Set as Main Aptos Wallet. <br />
                              Aptos tokens sent to you in the TowneSquare
                              ecosystem will be received here
                            </>
                          )
                        }
                      >
                        <button
                          onClick={() => handleAptosMainWallet(wallet.address)}
                        >
                          <img
                            src={
                              wallet.isMainWallet
                                ? "/profile/stared.png"
                                : "/profile/star.svg"
                            }
                            alt="aptos"
                            className="w-6 h-6"
                          />
                        </button>
                      </Tooltip>
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          dispatch(toggleDisconnectWallet(true));
                        }}
                        className="px-8 py-3 border rounded-[32px] border-white text-white"
                      >
                        Disconnect
                      </button>
                    </div>
                  </div>
                ))}
                {solanaList.map((wallet, index) => (
                  <div
                    tabIndex={index}
                    className="flex flex-row justify-between w-full"
                    key={index}
                  >
                    <div className="flex flex-row items-center justify-center gap-2 md:gap-4">
                      <div className="p-2 border rounded-full h-9 w-9 border-gray-light-2">
                        <img src={wallet.icon} alt="" />
                      </div>
                      <p className="text-[15px] font-bold">
                        {wallet.address.slice(0, 6) +
                          "..." +
                          wallet.address.slice(-6)}
                      </p>
                      <Tooltip
                        label={
                          wallet.isMainWallet ? (
                            <>
                              This is your Main Solana Wallet. <br />
                              Solana tokens sent to you in the TowneSquare
                              ecosystem will be received here
                            </>
                          ) : (
                            <>
                              Set as Main Solana Wallet. <br /> Solana tokens
                              sent to you in the TowneSquare ecosystem will be
                              received here
                            </>
                          )
                        }
                      >
                        <button
                          onClick={() => handleSolanaMainWallet(wallet.address)}
                        >
                          <img
                            src={
                              wallet.isMainWallet
                                ? "/profile/stared.png"
                                : "/profile/star.svg"
                            }
                            alt="solana"
                            className="w-6 h-6"
                          />
                        </button>
                      </Tooltip>
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          dispatch(toggleDisconnectWallet(true));
                        }}
                        className="px-8 py-3 border rounded-[32px] border-white text-white"
                      >
                        Disconnect
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="container flex items-center justify-center w-full p-4 border border-gray-light-2 rounded-br-2xl rounded-bl-2xl">
            <PrimaryButton
              onClick={() => {
                dispatch(toggleWalletPanel(true));
              }}
              className="bg-primary-default md:w-[200px] h-[51px] py-3 px-8 rounded-[200px] text-black font-bold text-[16px] text-center"
            >
              Connect wallet
            </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageWalletModal;

const AptosWalletList: WalletType[] = [
  {
    icon: "/profile/aptos.svg",
    address: "0x193D64be6b2442fD0BBe3CCdDd4A1B723ed090C1",
    isMainWallet: true,
  },
  {
    icon: "/profile/aptos.svg",
    address: "0x193D64be6b2442fD0BBe3CCdDd4A1B723ed090C2",
    isMainWallet: false,
  },
  {
    icon: "/profile/aptos.svg",
    address: "0x193D64be6b2442fD0BBe3CCdDd4A1B723ed090C3",
    isMainWallet: false,
  },
  {
    icon: "/profile/aptos.svg",
    address: "0x193D64be6b2442fD0BBe3CCdDd4A1B723ed090C4",
    isMainWallet: true,
  },
];

const SolanaWalletList: WalletType[] = [
  {
    icon: "/header/solana.png",
    address: "0x193D64be6b2442fD0BBe3CCdDd4A1B723ed090C1",
    isMainWallet: true,
  },
  {
    icon: "/header/solana.png",
    address: "0x193D64be6b2442fD0BBe3CCdDd4A1B723ed090C2",
    isMainWallet: false,
  },
  {
    icon: "/header/solana.png",
    address: "0x193D64be6b2442fD0BBe3CCdDd4A1B723ed090C3",
    isMainWallet: false,
  },
  {
    icon: "/header/solana.png",
    address: "0x193D64be6b2442fD0BBe3CCdDd4A1B723ed090C4",
    isMainWallet: false,
  },
];
