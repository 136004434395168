import React from "react";
import Share from "../../../components/share";
import { useAppSelector } from "../../../state/hooks";
import { WalletType } from "../../../type/walletType";

const MyRanking = () => {
  const myRanking = useAppSelector((state) => state.leaderboardState.myRank);
  const visitorMode = useAppSelector((state) => state.globalState.visitorMode);
  const walletType = useAppSelector((state) => state.globalState.walletType);
  const isAptos = walletType == WalletType.APTOS;

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-2xl font-bold md:text-3xl">{isAptos ? 'MY APTOS RANKING' : 'MY MOVEMENT RANKING'}</h1>
      <div className="flex flex-col items-center justify-center gap-4 mt-4 md:flex-row">
        <div className="container px-8 py-1 w-auto flex gap-2 justify-center items-center border-[3px] md:border-4 border-secondary-default rounded-full ">
          <p className="text-[38px] md:text-[57px] font-semibold">
            {visitorMode ? "???" : myRanking.toLocaleString('en-US', { minimumIntegerDigits: 1 })}
          </p>
        </div>
        <Share />
      </div>
      <p className="mt-4 text-center text-sm md:text-base w-[80%] md:w-full">
        Rankings are based on your Cred points and activities.<br />
        Some activities may take longer for the blockchain to update.
      </p>
    </div>
  );
};

export default MyRanking;
