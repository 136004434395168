import React, { useState } from 'react';

import { useAptosWallet } from '@razorlabs/wallet-kit';

import { toggleWalletPanel } from '../state/dialog';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { useAutoConnect } from '../wallet-adapter/AutoConnectProvider';

const MovementWallets = () => {
  const { configuredWallets, detectedWallets, connected, address, account, select } =
    useAptosWallet();
  const { bWalletPanel } = useAppSelector(
    (state) => state.dialogState
  );
  const dispatch = useAppDispatch();
  const { setWalletType } = useAutoConnect();

  const onConnect = async (wallet: any) => {
    try {
      setWalletType("movement");
      await select(wallet.name);

      if (bWalletPanel) {
        dispatch(toggleWalletPanel(false));
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <div className="flex flex-col gap-4 pb-4 mt-4 min-h-[430px]">
      {configuredWallets.map((wallet, index) => {
        const isWalletReady = wallet.installed;
        return (
          <button
            className={`flex justify-between items-center  text-white py-2 px-6 rounded ${isWalletReady ? "hover:bg-gray-light-8" : "cursor-not-allowed"
              }`}
            disabled={!isWalletReady}
            key={wallet.name + "movement"}
            onClick={() => onConnect(wallet)}
          >
            <div className="flex items-center gap-4 font-bold">
              <img src={wallet.iconUrl} alt="uri" className="w-10 h-10" />
              {wallet.name}
            </div>
            {isWalletReady && (
              <p className="text-[14px] text-gray-light-7">Detected</p>
            )}
          </button>
        );
      })}
    </div>
  );
};

export default MovementWallets;
