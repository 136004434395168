import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { WalletType } from "../type/walletType";
import { RewardType } from "../type/rewardType";

interface globalStates {
  step: number;
  visitorMode: boolean;
  walletType: WalletType;
  rewardType: RewardType;
}

const initialState: globalStates = {
  step: 0,
  visitorMode: false,
  walletType: WalletType.APTOS,
  rewardType: RewardType.GENERAL,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    resetGlobal: (state, action: PayloadAction<boolean>) => {
      state.step = 0;
      state.visitorMode = false;
    },
    updateStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    updateVisitorMode: (state, action: PayloadAction<boolean>) => {
      state.visitorMode = action.payload;
    },
    updateWalletType: (state, action: PayloadAction<WalletType>) => {
      state.walletType = action.payload;
    },
    updateRewardType: (state, action: PayloadAction<RewardType>) => {
      state.rewardType = action.payload;
    },
  },
});

export const {
  resetGlobal,
  updateStep,
  updateVisitorMode,
  updateWalletType,
  updateRewardType,
} = globalSlice.actions;
export default globalSlice.reducer;
