import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useWallet } from '../../hook/useWallet';
import { reset } from '../../state/credpoints';
import { toggleChangeAvatarPanel, toggleWalletPanel } from '../../state/dialog';
import { resetGlobal } from '../../state/global';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { reset as resetLeaderboard } from '../../state/leaderboard';
import { resetProfile, updateProfileImage } from '../../state/profile';
import { getBoringAvatar } from '../../util/boringAvatar';
import jwtEncode from 'jwt-encode';
import { SECRETKEY } from '../../constants';
import { uploadAvatar } from '../../api/profile';

const ConnectButton = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { connected, address, disconnect, network } = useWallet();

  const [isOpen, toggleOpen] = useState(false);
  const profile = useAppSelector(state => state.profileState);
  const profileViewed = useAppSelector(state => state.profileState.profileViewed);
  const avatar = useAppSelector(state => state.profileState.avatar);
  const profileName = useAppSelector(state => state.profileState.profileName);
  const userName = useAppSelector((state) => state.profileState.userName);

  const onDisconnet = () => {
    dispatch(reset(true));
    dispatch(resetLeaderboard(true));
    dispatch(resetProfile(true));
    dispatch(resetGlobal(true));
    disconnect();

    setTimeout(() => {
      navigate('/')
    }, 2000)
  }

  const ref = useRef<any>(null);;
  useEffect(() => {
    const ondrawImage = async () => {
      if (!ref.current) return;
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.src = ref.current.src;
      console.log(image);
      const canvas = document.createElement("canvas");
      canvas.width = 40;
      canvas.height = 40;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(image, 0, 0, 40, 40);
      var jpegUrl = canvas.toDataURL("image/jpeg");
      console.log("JPEGURL", jpegUrl);
      const token = jwtEncode({ wallet: address }, SECRETKEY);
      if (address && ref.current) {
        const res = await uploadAvatar(address, network, token, jpegUrl);
        if (res) {
          console.log(res);
          dispatch(updateProfileImage(res.url))
          dispatch(toggleChangeAvatarPanel(false))
        }
      }
    }
    if (avatar == "" || avatar == null) {
      ondrawImage();
    }
  }, [avatar])

  return (
    <>
      {connected ? (
        <>
          <div
            className="w-[200px] flex flex-col items-center gap-6 px-6 py-3 rounded-3xl border border-gray-light-1 hover:bg-[#ffffff30] cursor-pointer active:border-2 active:border-white active:bg-black"
            onClick={(e) => {
              e.stopPropagation();
              toggleOpen(!isOpen);
            }}
          >
            <div className="flex items-center gap-2">
              <div className="w-8 h-8">
                <img
                  src={avatar == "" ? getBoringAvatar(address) : avatar}
                  crossOrigin={avatar === "" ? 'anonymous' : undefined}
                  ref={ref}
                  className="w-8 h-8 rounded-full"
                  alt="ellipse"
                />
              </div>
              <span className="text-center font-[Inter] group-hover:font-bold">
                {userName ? userName : address == profileName || !profileName ? address?.slice(0, 4) + "..." + address?.slice(-3) : profileName.slice(0, 9) + "..."}
                <span className="text-xs">&nbsp;&nbsp;&nbsp;{isOpen ? "▲" : "▼"}</span>
              </span>
            </div>
            {isOpen && (
              <>
                {profile.success &&
                  <div className="relative z-50 flex items-center group">
                    <p onClick={() => navigate("/profile")} className="text-center font-[Inter] group-hover:font-bold ml-4 mr-2">
                      My profile
                    </p>
                    {!profileViewed && <img src="/credpoints/icon-warning-sm.svg" className="w-[16px] h-[16px] mr-2 text-white" alt="cred" />}
                  </div>
                }
                <div className="relative z-50 group" onClick={() => onDisconnet()}>
                  <p className="text-center font-[Inter] group-hover:font-bold">
                    Disconnect Wallet
                  </p>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div
            className="w-[200px] mt-3 flex justify-center cursor-pointer"
            onClick={() => dispatch(toggleWalletPanel(true))}
          >
            <p className="font-semibold whitespace-nowrap">Connect Wallet</p>
          </div>
        </>
      )}
    </>
  );
};

export default ConnectButton;
