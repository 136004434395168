import React from "react";

import { useState } from "react";
import { toggleWalletPanel } from "../../state/dialog";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import WalletButtons from "../../wallet-adapter/WalletButtons";
import { useNavigate } from "react-router-dom";
import SolWallets from "../../solana-wallet-adapter/solWallets";
import { WalletType } from "../../type/walletType";
import { updateWalletType } from "../../state/global";
import MovementWallets from "../../movement-wallet-adapter/movementWallets";

const WalletModal = () => {
  const isOpen = useAppSelector((state) => state.dialogState.bWalletPanel);
  const sentRequest = useAppSelector((state) => state.dialogState.bWalletHold);
  const dispatch = useAppDispatch();

  const walletType = useAppSelector((state) => state.globalState.walletType);

  const onChangeWallet = (type: WalletType) => {
    dispatch(updateWalletType(type));
  };

  return (
    <div
      className={`${isOpen ? "block" : "hidden"
        } absolute z-40 inset-0 h-full flex justify-center items-center bg-gray-dark-1/90`}
    >
      <div className="relative w-[400px]  bg-gray-light-6 border-gray-light-3 rounded-[20px]">
        <div className="bg-gray-light-8 rounded-t-[20px] p-4 flex justify-between items-center mb-2">
          <p className="text-2xl font-bold">
            {sentRequest ? "Connect Aptos wallet" : "Connect wallet"}
          </p>
          <div
            className="cursor-pointer"
            onClick={() => dispatch(toggleWalletPanel(false))}
          >
            {/* <p className="text-3xl font-bold">×</p> */}
            <img src="/home/close.svg" alt="" />
          </div>
        </div>
        <div className="flex flex-row items-center justify-center w-full h-12 text-center">
          {/* <div className="flex flex-col items-center justify-center w-full ml-2">
            <button
              onClick={() => onChangeWallet(WalletType.SOLANA)}
              className={`flex items-center justify-center w-full h-full py-[17px]`}
            >
              <img src="/header/solana.svg" className="h-[14px]" alt="" />
            </button>
            <div
              className="w-full h-[4px] border-b border-b-gray-light-3"
              style={
                walletType === WalletType.SOLANA
                  ? { height: "4px", background: "linear-gradient(94.74deg, rgba(153, 69, 255, 1) 16.43%, rgba(25, 250, 156, 1) 108.74%)" }
                  : {}
              }
            ></div>
          </div> */}
          <div className="flex flex-col items-center justify-center w-full">
            <button
              onClick={() => onChangeWallet(WalletType.APTOS)}
              className={`flex items-center justify-center w-full h-full py-4`}
            >
              <img src="/home/aptos.svg" className="h-[16px]" alt="" />
            </button>
            <div
              className="w-full h-[4px] border-b border-b-gray-light-3"
              style={
                walletType === WalletType.APTOS
                  ? { height: "4px", backgroundColor: "#FFFFFF" }
                  : {}
              }
            ></div>
          </div>
          <div className="flex flex-col items-center justify-center w-full">
            <button
              onClick={() => onChangeWallet(WalletType.MOVEMENT)}
              className={`flex items-center justify-center w-full h-full py-[17px]`}
            >
              <img src="/header/movement.svg" className="h-[14px]" alt="" />
            </button>
            <div
              className="w-full h-[4px] border-b border-b-gray-light-3"
              style={
                walletType === WalletType.MOVEMENT
                  ? { height: "4px", backgroundColor: "#FFDA34" }
                  : {}
              }
            ></div>
          </div>
          {/* <div className="flex flex-col items-center justify-center w-full">
            <button
              onClick={() => onChangeWallet(WalletType.MONAD)}
              className={`flex items-center justify-center w-full h-full py-[17px]`}
            >
              <img src="/header/monad.svg" className="h-[14px]" alt="" />
            </button>
            <div
              className="w-full h-[4px] border-b border-b-gray-light-3"
              style={
                walletType === WalletType.MONAD
                  ? { height: "4px", backgroundColor: "#FFDA34" }
                  : {}
              }
            ></div>
          </div> */}
        </div>
        {sentRequest && (
          <div
            role="status"
            className={`absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2`}
          >
            <svg
              className="w-8 h-8 animate-spin"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="50"
                cy="50"
                r="45"
                fill="none"
                stroke="bg-gray-light-6"
                strokeWidth="8"
              ></circle>
              <circle
                cx="50"
                cy="50"
                r="45"
                fill="none"
                stroke="white"
                strokeWidth="8"
                strokeDasharray="350.91"
                strokeDashoffset="152.94"
                className="animate-rotate"
              ></circle>
            </svg>
          </div>
        )}
        <div className={`${sentRequest && "opacity-50 cursor-not-allowed"}`}>
        {/* <div
            className={`${walletType === WalletType.SOLANA ? "block" : "hidden"
              } `}
          >
            <SolWallets />
          </div> */}
          <div
            className={`${walletType === WalletType.APTOS ? "block" : "hidden"
              } `}
          >
            <WalletButtons />
          </div>
          <div
            className={`${walletType === WalletType.MOVEMENT ? "block" : "hidden"
              } `}
          >
            <MovementWallets />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletModal;
