import { useWallet as useAptosWallet } from "@aptos-labs/wallet-adapter-react";
import { useAptosWallet as useMovementWallet } from "@razorlabs/wallet-kit";
import { WalletType } from "../type/walletType";
import { useAppSelector } from "../state/hooks";
import { getNetworkName } from "../util";
import { useEffect } from "react";

export const useWallet = () => {
  const walletType = useAppSelector((state) => state.globalState.walletType);

  const { connected: aptosConnected, account, disconnect: aptosDisconnect } = useAptosWallet();
  const { connected: movementConnected, account: movementAccount, disconnect: movementDisconnect } = useMovementWallet();


  const connected = walletType == WalletType.APTOS ? aptosConnected : (movementConnected && movementAccount && movementAccount.address ? true : false);
  const address = walletType == WalletType.APTOS ? account?.address : movementAccount?.address;
  const disconnect = walletType == WalletType.APTOS ? aptosDisconnect : movementDisconnect;
 
  useEffect(() => {
    setTimeout(() => {
      if (movementConnected && (!movementAccount || !movementAccount?.address))
        movementDisconnect();
    }, 300)
  }, [movementConnected, account])

  const network = getNetworkName(walletType);
  return { connected, address, disconnect, network};
}