import React from "react";

import GlobalLink from "./globalLink";
import TwitterLink from "./twitterLink";
import Tooltip from "../tooltip";

interface Item {
  descr: string;
  price: string;
}

interface Section {
  title: string;
  items: Item[];
}
interface Props {
  imgUrl?: string;
  text?: string;
  text_sm?: string;
  twitterLink?: string;
  globalLink?: string;
  tipData?: Section[];
}

const NftCollection: React.FC<Props> = ({
  imgUrl,
  text,
  twitterLink,
  globalLink,
  text_sm,
  tipData,
}) => {
  return (
    <div className="flex justify-between md:w-[314px] h-[64px] items-center border-b-[1px] border-[#626262] mx-5">
      <div className="flex">
        <img src={imgUrl} className="w-6 h-6 rounded-full" />
        <div className="grid">
          <p className="text-4 font-[500] mx-2">{text}</p>
          {text_sm && (
            <p className="text-[12px] font-[400] text-gray-light-3 ml-2">
              {text_sm}
            </p>
          )}
        </div>
        {tipData && (
          <Tooltip
            label={
              <div className="w-fit h-fit">
                {tipData &&
                  tipData.map((section, index) => (
                    <div key={index} className="">
                      <div
                        color="white"
                        className="font-normal text-gray-light-3 text-[13px]"
                      >
                        <p className="my-2">{section.title}</p>
                      </div>
                      <div
                        color="white"
                        className="font-normal"
                      >
                        <div className="flex-col gap-2">
                          {section.items.map((item, i) => (
                            <div key={i} className="flex items-center">
                              <p className="text-[13px] mr-2">{item.descr}</p>
                              <p className="text-[13px] flex text-gray-light-3">
                                {item.price}
                                <img
                                  className="w-[14px] mx-1"
                                  src="/credpoints/cred.svg"
                                  alt="copy"
                                />
                                /day
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            }
          >
            <img src={"/credpoints/question_tip.svg"} className="w-5 h-5" />
          </Tooltip>
        )}
      </div>
      <div className="flex">
        <TwitterLink link={twitterLink} />
        <GlobalLink link={globalLink} />
      </div>
    </div>
  );
};

export default NftCollection;
