import React from "react";
import BridgeShowcase from "../../../../components/defi/bridgeShowcase";
import GlobalLink from "../../../../components/defi/globalLink";
import TwitterLink from "../../../../components/defi/twitterLink";

interface Props {
  data?: any;
}

const Tradebook: React.FC<Props> = ({ data }) => {
  return (
    <div id="swap" className="flex flex-col overflow-y-auto">
      <div className="flex flex-col h-fit border-b-[1px] border-[#626262] pb-4 mb-2">
        <div className="flex items-center justify-between h-8 mb-4 md:mt-4">
          <div className="flex">
            <img
              src="/credpoints/defi/kana_labs.svg"
              className="w-6 h-6"
            />
            <p className="ml-2 font-semibold text-4">Kana Labs</p>
          </div>
          <div className="flex">
            <TwitterLink link="https://twitter.com/PontemNetwork" />
            <GlobalLink link="https://www.liquidswap.com" />
          </div>
        </div>
        <div className="flex flex-col md:h-[49px]">
          <div className="flex flex-wrap justify-start gap-y-2">
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/aptos.svg", text: "APT" }}
              Item2={{ imgUrl: "/credpoints/token_icons/cake.svg", text: "CAKE" }}
            />
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/aptos.svg", text: "APT" }}
              Item2={{ imgUrl: "/credpoints/token_icons/usdc.svg", text: "USDC" }}
            />
          </div>
          <p className="text-[12px] text-gray-light-3 mt-2">Supported bridges for <b>USDC</b>: LayerZero</p>
        </div>
      </div>

      <div className="flex flex-col h-fit border-b-[1px] border-[#626262] pb-4 mb-2">
        <div className="flex items-center justify-between h-8 mb-4 md:mt-4">
          <div className="flex">
            <img
              src="/credpoints/defi/panora.svg"
              className="w-6 h-6"
            />
            <p className="ml-2 font-semibold text-4">Panora</p>
          </div>
          <div className="flex">
            <TwitterLink link="https://twitter.com/PancakeSwap" />
            <GlobalLink link="https://pancakeswap.finance" />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-wrap justify-start gap-y-2">
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/aptos.svg", text: "APT" }}
              Item2={{ imgUrl: "/credpoints/token_icons/usdc.svg", text: "USDC" }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col h-fit border-b-[1px] border-[#626262] pb-4 mb-2">
        <div className="flex items-center justify-between h-8 mb-4 md:mt-4">
          <div className="flex">
            <img
              src="/credpoints/defi/merkle_trade.svg"
              className="w-6 h-6"
            />
            <p className="ml-2 font-semibold text-4">Merkle Trade</p>
          </div>
          <div className="flex">
            <TwitterLink link="https://twitter.com/PancakeSwap" />
            <GlobalLink link="https://pancakeswap.finance" />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-wrap justify-start gap-y-2">
            <BridgeShowcase
              Item1={{ imgUrl: "/credpoints/aptos.svg", text: "APT" }}
              Item2={{ imgUrl: "/credpoints/token_icons/usd.svg", text: "USD" }}
            />
          </div>
          <p className="text-[12px] text-gray-light-3 mt-2">Supported bridges for <b>USDC</b>: LayerZero</p>
        </div>
      </div>
    </div>
  );
};

export default Tradebook;
